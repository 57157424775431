// Every string is in the form:
// key: 'String text',
// The key is in light blue and the string text is in peach.
// Only ever translate the string text in a PEACH colour.
// Also don't edit the {variables} in curly brackets (see below for more info)

// Most strings have green comments on the line above to hopefully help explain and give context.

// *** {0}, {1}, etc ***
// These are variables inserted into the text. The variables will be explained in the line(s) above.

// *** {namedStrings} ***
// There are using other strings from the rest of the file.
// e.g. 'The app is {appName}!' would result in "The app is Can't Stop, Won't Stop"
// If the use of that string doesn't actually work in the local language you can
// just remove the "{namedString}" and write out the full text.

// *** _plural ***
// In English and many other languages there are singular and plural forms of words, such as "1 cat" vs "2 cats".
// When a variable (like {0}) is a number, sometimes there needs to be two strings, such as:
// xCats: '{0} cat', (when {0} is 1)
// xCats_plural: '{0} cats', (when {0} is anything other than 1)
// If the new language doesn't have plurals, you can just delete the lines where the key ends with "_plural".

const de = {
  lang: 'de',
  appStoreLang: 'de',
  playStoreLang: 'de',
  dateAt: ' um ',
  // App Name
  appName: "Can't Stop, Won't Stop",

  // Version change log title. e.g. "What's New In 3.6?"
  whatsNewInX: `Neu in {0}`,

  // Alert in the menu if there's no user data, asking if their account is missing
  recoveryFailedTitle: 'Sind deine Spiele verschwunden?',
  recoveryFailedDesc:
    'Wenn deine Spiele nach der Installation eines Updates verschwunden sind, keine Sorge! Bitte schreibe mir eine E-Mail mit deinem vorherigen Benutzernamen und ich werde dich wieder zum Laufen bringen.',

  // General
  // Question confirming the user wants to do the action
  areYouSure: 'Bist du dir sicher?',
  notConnected: 'Du bist derzeit nicht mit dem Server verbunden.',
  // Email button
  emailMe: 'Schreib mir eine E-Mail',
  // Email Address text field
  emailAddress: 'E-Mail-Adresse',
  // Helper text for privacy of email
  emailHelper: 'Deine E-Mail wird nur zur Wiederherstellung deines Kontos verwendet.',
  // When a user is also an admin
  admin: 'Admin',
  // The full paid version of the app
  fullEdition: 'Premium',
  // The limited free version of the app
  basicEdition: 'Basisversion',
  // The website version of the app
  webEdition: 'Webversion',
  // An unknown type of user
  unknownUser: 'Unbekannter Benutzer',
  // Not connected to the internet
  offline: 'Offline',
  offlineGame: 'Offline-Spiel',
  offlineGameDesc:
    'Ein "Pass and Play"-Offline-Spiel, um mit Freunden im selben Raum zu spielen oder einfach gegen die KI zu üben.',
  // Button for downloading on the Apple App Store
  downloadAppStore: 'Im App Store herunterladen',
  // Button for downloading on the Google Play Store
  downloadPlayStore: 'Im Google Play Store erhältlich',
  // Button to expand the form and show the more advanced options
  showAdvanced: 'Erweiterte Optionen anzeigen',
  // Displayed when the app is connecting to the game server
  connecting: 'Verbinde',
  // Displayed when the app is loading a game or page
  loading: 'Lädt',
  // A star is shown next to players with the best scores
  topPlayer: 'Bester Spieler',
  // An icon is shown next to new players
  newPlayer: 'Neuer Spieler',

  // AI Players (for offline games)...
  // The speed of the AI player
  aiSpeed: 'KI-Spieler Geschwindigkeit',
  // The slowest AI player
  slowSpeed: 'Langsam',
  // The normal speed AI player
  normalSpeed: 'Normal',
  // The fast speed AI player
  fastSpeed: 'Schnell',
  // The fastest AI player, where moves are instant
  instantSpeed: 'Sofort',

  // Human player
  human: 'Mensch',
  // AI player risk level 1: Safe
  aiSafe: 'Sicher',
  // AI player risk level 2: Normal
  aiNormal: 'Normal',
  // AI player risk level 3: Risky
  aiRisky: 'Riskant',
  // AI player risk level 4: Crazy
  aiCrazy: 'Verrückt',
  // AI player risk level 5: Extreme
  aiExtreme: 'Extrem',

  // AI player
  ai: 'KI',
  humanFull: '{human}',
  aiSafeFull: '{ai}: {aiSafe}',
  aiNormalFull: '{ai}: {aiNormal}',
  aiRiskyFull: '{ai}: {aiRisky}',
  aiCrazyFull: '{ai}: {aiCrazy}',
  aiExtremeFull: '{ai}: {aiExtreme}',

  // Note for when a feature is only available in the full edition:
  onlyInFull: 'Nur für {fullEdition}-Benutzer verfügbar.',
  // The same as above but in brackets and shorter
  onlyInFullBrackets: '({fullEdition} nur)',

  // MenuPage
  // {0} is the name of the app in bold
  welcomeToApp: 'Willkommen bei {0}!',
  // Welcome intro
  welcomeToAppDesc:
    'Benutze das Einstellungsmenü, um deinen Benutzernamen zu ändern, und das Hilfemenü, wenn du dir nicht sicher bist, wie man spielt.',
  freeJoinPublic:
    "UPDATE: Spieler mit der {basicEdition} können jetzt öffentlichen Spielen beitreten! Tippe unten auf '{joinOnlineGame}'...",

  // Welcome message options
  // {0} is the username
  hiUser1: 'Oh hi, {0}!',
  hiUser2: 'Willkommen, {0}!',
  hiUser3: 'Hallo, {0}!',
  hiUser4: 'Hey, {0}!',
  hiUser5: 'Hallo, {0}!',

  // Button to create a new online game
  newOnlineGame: 'Online-Spiel erstellen',
  // Button to join an existing online game
  joinOnlineGame: 'Online-Spiel beitreten',
  // Button to start and play a new offline game
  newOfflineGame: 'Neues Offline-Spiel',
  // Confirming with the user that they want to start a new offline game when they already have an existing game
  newOfflineGameConfirm: 'Neues Offline-Spiel?',
  newOfflineGameConfirmDesc: 'Dies beendet das aktuelle Offline-Spiel. {areYouSure}',
  // Button to continue playing an existing offline game
  continueOfflineGame: 'Offline-Spiel fortsetzen',

  // Button to take the user to their active games
  // {0} is the number of active games the user has
  yourXGames: 'Dein {0} Spiel',
  yourXGames_plural: 'Deine {0} Spiele',

  // Button to take the user to one specific game
  // {0} is the game ID
  goToRoom: 'Gehe zu #{0}',

  // Button to join a specific game (usually by invitation)
  // {0} is the game ID
  joinX: 'Beitreten #{0}',

  // Button to go to the game rules
  howToPlay: 'Wie man spielt',

  // Rules heading
  rules: 'Regeln',
  // Rules intro lines
  rulesIntro1:
    'In jedem Zug würfelt der Spieler mit den vier Würfeln, dann teilt er sie in zwei Paare und addiert jedes Paar. Zum Beispiel könnte ein Spieler, der eine 1, 2, 3 und 6 würfelt, sie als 5 & 7, 4 & 8 oder 3 & 9 gruppieren.',
  rulesIntro2:
    'Wähle eine Paarung, um deine Marker in den entsprechenden Spalten zu bewegen. Du hast nur eine begrenzte Anzahl von Markern pro Zug.',
  rulesIntro3:
    'Wenn es keine möglichen Würfelpaarungen gibt, endet dein Zug und die Marker gehen verloren. Wenn du dich entscheidest zu stoppen, werden deine Marker durch deine Farbe ersetzt.',
  rulesIntro4:
    'Die 2- und 12-Spalten sind kurz, aber diese Werte zu würfeln ist selten, während die 7-Spalte die längste, aber am häufigsten vorkommende ist.',
  rulesIntro5:
    'Sobald ein Spieler die Spitze einer Spalte erreicht und stoppt, beansprucht er die Spalte und sie wird zu seinem Punktestand hinzugefügt. Diese Spalte kann im Rest des Spiels nicht mehr gespielt werden. Sobald ein Spieler die Zielpunktzahl erreicht, endet das Spiel.',

  // Online games with other people
  onlineGames: 'Online-Spiele',
  onlineGamesDesc:
    'Du kannst online gegen andere Spieler spielen, entweder indem du dein eigenes öffentliches oder privates Spiel erstellst oder einem bestehenden Spiel beitrittst.',

  // Offline games with friends in the same room or against AI
  offlineGames: 'Offline-Spiele ("Pass and Play")',
  offlineGamesDesc:
    'Du kannst ein Offline-Spiel mit einem Gerät spielen und das Gerät zwischen den Spielern weitergeben.',

  // Section about the original board game
  originalRules: 'Originalspiel',
  videoIntro:
    '"Kann nicht aufhören, will nicht aufhören" ist inspiriert vom ursprünglichen Brettspiel "Kann nicht aufhören", ist aber in keiner Weise damit verbunden. Hier sind ein paar YouTube-Videos, die das ursprüngliche Brettspiel erklären, falls du es noch nie gespielt hast:',
  // {0} is the number of the video (e.g. 1 => "Video 1")
  videoX: 'Video {0}',

  // Privacy Policy
  privacyPolicyDesc:
    'Es werden keine persönlichen Daten von dir gesammelt. Deine App-Einstellungen und die Spiele, die du spielst, werden an einen Server gesendet, aber die Daten werden nur vorübergehend gespeichert und niemals an Dritte weitergegeben. Die Angabe deiner E-Mail-Adresse ist optional und wird nur zur Wiederherstellung deines Kontos verwendet. Schreibe an chris@mento.co.uk, um zu verlangen, dass deine Daten gelöscht werden.',

  probabilityCalculator: 'Wahrscheinlichkeitsrechner',
  probabilityCalculatorDesc:
    'Ich habe ein kleines Tool erstellt, um dir zu helfen, die Wahrscheinlichkeiten für das Würfeln einer oder mehrerer Spalten zu verstehen. Es könnte hilfreich sein!',

  // Game settings
  // {0} is the number of columns
  xColumns: '{0} Spalte',
  xColumns_plural: '{0} Spalten',
  xColumnsAltDesc: 'Anzahl der Spalten, die zum Gewinnen erforderlich sind.',
  // {0} is the number of columns
  xColumnsDesc: 'Der erste Spieler, der {0} Spalte vervollständigt, gewinnt das Spiel.',
  xColumnsDesc_plural: 'Der erste Spieler, der {0} Spalten vervollständigt, gewinnt das Spiel.',

  // {0} is the number of markers
  xMarkers: '{0} Marker',
  xMarkers_plural: '{0} Marker',
  xMarkersAltDesc: 'Maximale Anzahl von Spalten pro Zug.',
  // {0} is the number of columns
  xMarkersDesc: 'Du kannst maximal {0} Spalte pro Zug besetzen.',
  xMarkersDesc_plural: 'Du kannst maximal {0} Spalten pro Zug besetzen.',

  // Optional game mode 1
  forceMax: 'Maximale Marker erzwingen',
  forceMaxDesc:
    'Der Spieler muss eine Würfelkombination wählen, die die meisten neuen Marker verwendet.',

  // Optional game mode 2
  preventSkip: 'Übersprungene Züge verhindern',
  preventSkipDesc: 'Würfeln Sie erneut, damit ein Spieler seinen ganzen Zug nie verliert.',

  // Optional game mode 3
  preDetermine: 'Nächsten Würfelwurf vorbestimmen',
  preDetermineDesc:
    'Die nächsten Würfelzahlen werden zufällig geworfen, bevor du {continue} oder {stop} tippst.',

  // Optional game mode 4
  doubleHeight: 'Doppelte Höhe',
  doubleHeightDesc: 'Alle Spalten sind doppelt so hoch, sodass die Spiele länger dauern.',

  // Public games can be seen and joined by anyone
  public: 'Öffentlich',
  public_full: 'Öffentliches Spiel',
  publicDesc: 'Öffentliche Spiele können von jedem gesehen und beigetreten werden.',

  // Private games can only be joined using the room code
  private: 'Privat',
  private_full: 'Privates Spiel',
  privateDesc: 'Private Spiele können nur mit dem Raumcode beigetreten werden.',

  // Heading for the player types (which version of the app each player is using)
  userTypes: 'Spielertypen',

  // Game Info
  // {0} is the game ID
  currentGameX: 'Aktuelles Spiel #{0}',
  // {0} is a relative time in the past, for example "5 minutes ago"
  lastActivityX: 'Letzte Aktivität {0}',

  // For when a time is too recent to show the exact time
  justNow: 'gerade jetzt',

  // Alert message shown when the user presses the button to copy something to the clipboard
  gameIdCopied: '{gameId} in die Zwischenablage kopiert!',
  websiteCopied: 'Website in die Zwischenablage kopiert!',

  // Waiting
  // {0} is the number of players joined so far
  // {1} is the total number of players that are needed for the game to start
  xOfYPlayersJoined: '{0} von {1} Spielern sind beigetreten:',

  // Winning turn
  // {0} is the column number(s)
  // {1} is the Turn Progress percentage
  winningMoveSummaryShort: '{0} · {1}%',
  winningMoveSummaryLong: `Beendet durch Vervollständigung der Spalte {0} mit einem 'Zugfortschritt' von {1}%`,

  // Full turn progress for the tooltip
  // {0} is the Turn Progress percentage
  turnProgressFull: `Aktueller 'Zugfortschritt' beträgt {0}%`,
  // If it was the previous player's turn:
  turnProgressLast: `Der vorherige 'Zugfortschritt' betrug {0}%`,

  // Cancel button
  cancel: 'Abbrechen',

  // Button to fully cancel/end the game
  cancelGame: 'Spiel abbrechen',
  // Confirmation about cancelling the game before it starts
  cancelGameDesc: 'Dies wird das Spiel für alle abbrechen. Bist du sicher?',

  // Button to decline a rematch
  declineRematch: 'Rematch ablehnen',
  // Button to view the previous game from a rematch
  viewPrevious: 'Vorheriges Spiel anzeigen',

  // Share App
  shareMessage: `🎲🎲 Schließ dich mir an und spiele Can't Stop, das süchtig machende Würfelspiel! 🎲🎲`,

  // QR linked devices
  linkToWeb: 'Link zur Website',
  linkToWebDesc: 'Sieh dir deine Spiele in der {webEdition} an und spiele sie.',

  // Button to leave the game when waiting for other players to join
  leaveGame: 'Spiel verlassen',

  // Button to join a game
  joinGame: 'Spiel beitreten',
  // Button to join a rematch game
  joinRematch: 'Rematch beitreten',

  // {0} is the website URL
  inviteAtX: 'Lade Freunde ein, kostenlos beizutreten unter {0}',

  gameId: 'Spiel-ID',
  gameIdHash: '{gameId} #',

  // Join button
  join: 'Beitreten',
  enterGameId: 'Gib die {gameId} oben ein, um einem privaten Spiel eines Freundes beizutreten.',
  // {0} is the name of the app
  listGamesFullApp:
    'Wenn du eine Liste öffentlicher Spiele sehen möchtest, benötigst du die {0} App:',
  noPublicGames: 'Derzeit gibt es keine öffentlichen Spiele zum Beitreten.',
  newGameFullApp:
    'Warum nicht ein neues Spiel erstellen und deine Freunde einladen, kostenlos beizutreten (mit der {basicEdition} App oder der {webEdition})?',
  publicGames: 'Öffentliche Spiele',
  waiting: 'Warten',
  publicGamesWaiting: '{publicGames}: {waiting}',
  inProgress: 'Läuft',
  publicGamesInProgress: '{publicGames}: {inProgress}',
  recentlyFinished: 'Kürzlich beendet',
  publicGamesRecentlyFinished: '{publicGames}: {recentlyFinished}',

  // New Game
  createOnlineGame: 'Online-Spiel erstellen',
  createOnlineGameDesc:
    'Erstelle ein Online-Spiel und lade deine Freunde ein oder warte auf andere Spieler, die beitreten!',
  createOfflineGame: 'Offline-Spiel erstellen',
  createOfflineGameDesc:
    'Erstelle ein „Pass and Play“-Offline-Spiel, um mit Freunden im selben Raum zu spielen oder einfach nur zum Üben.',
  // {0} is the name of the app
  noNewGameWeb:
    'Die Web Edition von {0} erlaubt es nur, Spiele über ihre ID beizutreten. Wenn du neue Spiele erstellen möchtest, benötigst du die App:',
  // {0} is the name of the app
  noNewGameBasic:
    'Die Basic Edition von {0} erlaubt es nur, Spiele beizutreten und Offline-Spiele zu erstellen. Wenn du ein neues Online-Spiel erstellen möchtest, benötigst du die werbefreie Vollversion:',
  basicLimitMessage:
    'Die Basic Edition von {0} erlaubt es nur, fünf Spiele gleichzeitig mit eingeschränkten Einstellungen zu erstellen. Wenn du die Optionen ändern oder mehr Spiele erstellen möchtest, benötigst du die werbefreie Vollversion:',
  // {0} is the number of players
  xPlayers: '{0} Spieler',
  xPlayersDesc: 'Anzahl der Spieler im Spiel.',

  // Conceding
  concede: 'Nachgeben',
  conceded: 'Nachgegeben',
  xConceded: '{0} nachgegeben.',
  youConceded: 'Du hast nachgegeben.',
  xGamesConceded: '{0} Spiel nachgegeben ({1}%)',
  xGamesConceded_plural: '{0} Spiele nachgegeben ({1}%)',

  // Games played
  xGamesPlayed: '{0} abgeschlossenes Spiel',
  xGamesPlayed_plural: '{0} abgeschlossene Spiele',
  xGamesPlaying: '{0} aktives Spiel',
  xGamesPlaying_plural: '{0} aktive Spiele',
  // xStopRate: 'Kürzlich: {1}% / Insgesamt: {0}%',
  xStopRate: "{0}'s Stop-Quote",
  xStopRateDesc: '(Züge, die mit einem Stop enden)',

  vsFormDesc: 'Deine letzten Ergebnisse gegen {0}',

  recent: 'Kürzlich',
  allTime: 'Gesamt',

  concedeCheck:
    'Bist du sicher, dass du dieses Spiel aufgeben möchtest? Es kann nicht rückgängig gemacht werden und könnte deinen Punktestand/deinen Status beeinflussen.',

  // Rematch
  rematch: 'Rematch',
  invited: 'Eingeladen',
  rematchProposed: 'Rematch vorgeschlagen',

  // Replay from here button
  replay: 'Von hier aus wiederholen',
  // Button to stop the replay
  stopReplay: 'Wiederholung stoppen',

  // Recent form: Win
  formWin: 'Gewinn',
  // Recent form: Loss
  formLoss: 'Verlust',
  // Recent form: Conceded
  formConceded: 'Eingestanden',

  recentForm: "{0}'s Aktuelle Form",

  // Game Modes

  // The classic normal rules of the game
  classic: 'Klassisch',
  classicFull: 'Klassischer Modus',
  classicDesc: 'Die klassischen Regeln.',

  // The "blocking" variant of the game
  block: 'Blockierung',
  blockFull: '"Blockierungs"-Variante',
  blockDesc: 'Das Stoppen an einer der gleichen Positionen wie ein anderer Spieler ist blockiert.',

  // The "Jumping" variant of the game
  jump: 'Springen',
  jumpFull: '"Springen"-Variante',
  jumpDesc:
    'Automatisch über andere Spieler springen, sodass Spieler nie an denselben Positionen stehen.',

  // Game
  youWin: 'Du Gewonnen!',
  youWon: 'Du Hast Gewonnen!',
  // {0} is the winning player name
  xWins: '{0} Gewinnt!',
  xWon: '{0} Hat Gewonnen',
  youLost: 'Du Hast Verloren',
  xWinRate: '{0}% Gewinnquote',
  neither: 'Keiner',

  // Alert when there are no more possible moves
  noPossibleMoves: 'Keine möglichen Züge',

  // Button to stop and pass to the next player
  stop: 'Stop',

  gameOver: 'Spiel Vorbei!',
  // Button to start a new game
  newGame: 'Neues Spiel',
  // Notification and history
  gameStarted: 'Spiel gestartet!',
  // Button to end the game early
  endGame: 'Spiel Beenden',
  startGame: 'Spiel Starten',
  nextGame: 'Nächstes Spiel',

  // Confirmation about ending the game early
  endGameEarly: 'Spiel Früher Beenden?',
  endGameEarlyDesc: 'Dies beendet das aktuelle Spiel für alle. Bist du dir sicher?',

  yourTurn: 'Dein Zug',
  // {0} is the player's name
  xTurn: "{0}'s Zug",
  // The status of a finished game
  finished: 'Beendet',
  // The status of a game in progress
  playing: 'Spielen',
  // {0} is the number of players
  waitingForX: 'Warten auf {0}',
  // {0} is the number of players
  waitingForXPlayers: 'Warten auf {0} Spieler',
  waitingForXPlayers_plural: 'Warten auf {0} Spieler',
  // {0} is a relative time in the past, for example "2 days ago"
  createdX: 'Erstellt {0}',

  // New version
  newVersion: 'Neue Version Verfügbar',
  newVersionDesc: 'Version {0} ist jetzt zum Download bereit.',

  // Beta Tester
  becomeTester: 'Tester Werden?',
  becomeTesterDesc:
    'Wenn du neue Funktionen frühzeitig ausprobieren möchtest, schreib mir bitte eine E-Mail, um der Gruppe freiwilliger Tester beizutreten!',
  // Button to decline joining as a tester
  noThanks: 'Nein Danke!',

  // Warning message when push notification permissions are denied
  pushDeniedTitle: 'Push-Benachrichtigungen sind derzeit für diese App deaktiviert.',
  pushDeniedDesc:
    'Ohne sie wirst du nicht benachrichtigt, wenn du an der Reihe bist, und riskierst, Spiele automatisch einzugestehen.',

  // Settings
  settings: 'Einstellungen',
  // Button to set/save the player's username
  set: 'Festlegen',
  // To change if the app will make sounds or not
  sounds: 'Geräusche',
  // The player's name visible to others online
  username: 'Benutzername',
  // The app's language
  language: 'Sprache',
  // Lock screen awake
  lockScreen: 'Bildschirm während der Spiele wachhalten',
  // Player colour settings
  playerColours: 'Spielerfarben',
  // emoji tone
  emojiTone: 'Emoji-Ton',

  // Button to show more of the history moves
  showMore: 'Mehr Anzeigen',

  // User Key (for switching accounts)
  userKey: 'Benutzerkontoschlüssel',
  userKeyDesc: `Warnung: Verwende dies nur, wenn du weißt, was du tust. Teile es mit niemandem.`,

  // Send email requesting deletion of user account
  requestDeleteAccount: 'Löschung des Kontos Anfordern',

  // Show last turn in the display
  lastTurnSetting: `Visualisiere den vorherigen Zug jedes Spielers`,

  // Turn Progress toggle
  turnProgressSetting: `Zeige den 'Zugfortschritt' während der Spiele`,
  turnProgressSettingDesc: `'Zugfortschritt' ist der % der aktuellen Spalten, die abgeschlossen sind. Zum Beispiel, wenn du es schaffst, alle 3 Spalten in einem Zug von Grund auf zu vervollständigen, würde es 100% anzeigen. Sobald aktiviert, wird es oben rechts auf dem Spielbildschirm, im Geschichtsbereich und im Rematch-Bildschirm angezeigt.`,

  // *** Keyboard Shortcuts ***

  // Turn on/off keyboard shortcuts for games
  keyShortcutsSetting: 'Tastenkombinationen',

  // Spacebar key
  keySpace: 'Leertaste',

  // Ctrl key
  keyCtrl: 'Strg',

  // Shift key
  keyShift: 'Umschalt',

  // "Next" action
  keyActionNextDesc: '"Nächster" (fortfahren, nächster Raum oder einzelne Würfeloption).',
  keyActionNextNote: 'Hinweis: Wenn es einen "nächsten" Knopf gibt, hat er einen Rand.',

  // Dice option
  keyActionNumDesc: 'Wähle Würfeloption mit dieser Zahl.',
  keyActionNumNote: 'Hinweis: Wenn nur eine Option für 10-12 existiert, musst du nur 1 drücken.',

  // Dice position
  keyActionCtrlNumDesc: 'Wähle Würfeloption an dieser Position.',
  // {0} is Ctrl (or another key)
  keyActionCtrlNumNote: 'z.B. {0}+1 ist die erste Option',

  // Action to open the keyboard shortcuts instructions
  keyActionOpenHelpDesc: 'Öffne diese Anweisungen.',

  includeLostGames: 'Verlorene Spiele in Nächste Spiele Einbeziehen',
  includeLostGamesDesc:
    'Die "Gehe zu #" Knöpfe (die dich zu deinem nächsten Zug führen) werden Spiele einschließen, die du kürzlich verloren hast.',

  you: 'Du',

  // Your Games
  yourGames: 'Deine Spiele',
  notInGames: 'Du bist derzeit in keinen aktiven Spielen.',

  // Errors
  waitingLimit: 'Du hast dein Limit an neuen Spielen erreicht, die noch auf den Start warten.',
  basicLimit: 'Die {basicEdition} erlaubt dir nur, fünf Spiele gleichzeitig zu erstellen.',
  serverTimeout: 'Serverzeitüberschreitung. Versuche es erneut.',
  samePlayersLimit:
    'Du hast das Limit für die Anzahl aktiver Spiele mit genau denselben Spielern erreicht.',

  // Bot check
  typeOk: 'Bitte tippe "ok" unten ein, um fortzufahren...',

  // Error page
  somethingWrong: 'Etwas ist schiefgelaufen!',
  somethingWrongDesc:
    'Entschuldigung! Bitte sende mir eine E-Mail mit den folgenden Informationen und einer kurzen Beschreibung dessen, was du getan hast, und ich werde es so schnell wie möglich beheben:',

  // Common app localisations:
  privacyPolicy: 'Datenschutzbestimmungen',
  // Link to support the developer
  supportDeveloper: 'Kaufe dem Entwickler einen Kaffee',

  // Button to say no
  no: 'Nein',
  // Button to say yes
  yes: 'Ja',
  // Close/exit button
  close: 'Schließen',
  // Button to continue playing
  continue: 'Fortfahren',
  comingSoon: 'Demnächst verfügbar!',
  reset: 'Zurücksetzen',
  ok: 'OK',

  // The app's colour theme (light/dark)
  appTheme: 'App-Theme',
  appThemeSystem: 'Systemstandard',
  appThemeLight: 'Heller Modus',
  appThemeDark: 'Dunkler Modus',

  // *** User Account ***
  signedInAsX: 'Eingeloggt als {0}',
  signedInDesc: 'Du kannst dich von anderen Geräten anmelden.',
  signOut: 'Abmelden',
  notSignedIn: 'Nicht eingeloggt.',
  signInToSave:
    'Melde dich an, um dein Spielerprofil und die Einstellungen zu speichern. Du kannst dich von anderen Geräten anmelden!',
  signInNew:
    'Neu in der App? Registriere dich, um dein Spielerprofil und die Einstellungen zu speichern. Du kannst dich von anderen Geräten anmelden! Wenn du bereits ein Konto hast, melde dich unten an.',
  signInRegister: 'Anmelden / Registrieren',

  // The 3 below are joined together to form one message with the middle as a button
  guestModeWarning1: 'Du verwendest den Gastmodus.',
  guestModeWarning2Btn: 'Anmelden',
  guestModeWarning3: 'um dein Konto zu schützen und von anderen Geräten zu spielen.',

  // Screenshot text
  // Each screenshot has text above and text below, which is why each of the below are in 2:
  screenshotPlayAgainst: '"Can\'t Stop" Würfelspiel',
  screenshotPlayAgainst2: 'Gegen Freunde spielen, online und offline',
  screenshotCreateOnline: 'Online-Spiele erstellen',
  screenshotCreateOnline2: 'Inklusive "Jumping"- & "Blocking"-Varianten!',
  screenshotPlayFriends: 'Mit Freunden spielen',
  screenshotPlayFriends2: 'Bis zu 4 Spieler online!',
  screenshotJoinPublicPrivate: 'Öffentliche oder private Spiele beitreten',
  screenshotJoinPublicPrivate2: 'Spiele auch gegen Fremde!',
  screenshotOffline: "Pass 'n' Play",
  screenshotOffline2: 'Spiele Offline-Spiele mit Freunden',
  screenshotInvite: 'Freunde einladen, kostenlos zu spielen',
  screenshotInvite2: 'Mit der Basic Edition App oder Web Edition',
  screenshotLimitedFree: 'Eingeschränkte Funktionen mit der Basic Edition',
  screenshotLimitedFree2: 'Werbefinanziert und keine Online-Spiele erstellen können.',
  screenshotSettings: 'Einstellungen ändern',
  screenshotSettings2: 'Inklusive Licht- und Dunkelmodi',

  // App listing
  appStoreNameFull: "Can't Stop: Dice Game", // Maximum 50 characters
  appStoreSubtitleFull: 'Spiele gegen deine Freunde und würfle!', // Maximum 80 characters
  appStoreDescriptionFull:
    'Voll funktionsfähiges beliebtes Würfelspiel basierend auf dem Brettspiel "Can\'t Stop". Spiele online oder offline (Pass and Play). 2-4 Spieler.\n\n    Probiere verschiedene Variationen aus oder bleibe bei der klassischen Version!\n    \n    Deine Freunde können deinem Spiel beitreten, auch wenn sie die App nicht haben. Die App wird benötigt, um neue Spiele zu erstellen und Offline-Spiele zu spielen.',

  appStoreNameBasic: "Can't Stop: Dice Game (Basic)", // Maximum 50 characters
  appStoreSubtitleBasic: 'Spiele gegen deine Freunde und würfle kostenlos!', // Maximum 80 characters
  appStoreDescriptionBasic:
    'Dies ist die Basic Edition des beliebten Würfelspiels basierend auf dem Brettspiel "Can\'t Stop". Spiele online oder offline (Pass and Play). 2-4 Spieler.\n\n    Probiere verschiedene Variationen aus oder bleibe bei der klassischen Version!\n    \n    Die vollständige App wird benötigt, um neue Online-Spiele zu erstellen.',
};

export default de;
