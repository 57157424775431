// Every string is in the form:
// key: 'String text',
// The key is in light blue and the string text is in peach.
// Only ever translate the string text in a PEACH colour.
// Also don't edit the {variables} in curly brackets (see below for more info)

// Most strings have green comments on the line above to hopefully help explain and give context.

// *** {0}, {1}, etc ***
// These are variables inserted into the text. The variables will be explained in the line(s) above.

// *** {namedStrings} ***
// There are using other strings from the rest of the file.
// e.g. 'The app is {appName}!' would result in "The app is Can't Stop, Won't Stop"
// If the use of that string doesn't actually work in the local language you can
// just remove the "{namedString}" and write out the full text.

// *** _plural ***
// In English and many other languages there are singular and plural forms of words, such as "1 cat" vs "2 cats".
// When a variable (like {0}) is a number, sometimes there needs to be two strings, such as:
// xCats: '{0} cat', (when {0} is 1)
// xCats_plural: '{0} cats', (when {0} is anything other than 1)
// If the new language doesn't have plurals, you can just delete the lines where the key ends with "_plural".

const zh = {
  lang: 'zh',
  appStoreLang: 'cn',
  playStoreLang: 'zh',
  dateAt: '',
  appName: '不能停止，也不想停止',

  // Version change log title
  whatsNewInX: `版本 {0} 的新功能`,

  // Alert in the menu if there's no user data, asking if their account is missing
  recoveryFailedTitle: '你的游戏消失了吗？',
  recoveryFailedDesc:
    '如果你在安装更新后发现游戏消失了，别担心！请给我发邮件，告诉我你之前的用户名，我会帮你恢复。',

  // General
  areYouSure: '你确定吗？',
  notConnected: '你当前未连接到服务器。',
  emailMe: '发邮件给我',
  emailAddress: '电子邮件地址',
  emailHelper: '你的电子邮件仅用于恢复账户。',
  admin: '管理员',
  fullEdition: '高级版',
  basicEdition: '基础版',
  webEdition: '网络版',
  unknownUser: '未知用户',
  offline: '离线',
  offlineGame: '离线游戏',
  offlineGameDesc: '与同房间的朋友一起玩的“传递和玩”离线游戏，或仅仅与 AI 练习。',
  downloadAppStore: '在 App Store 下载',
  downloadPlayStore: '在 Google Play 获取',
  showAdvanced: '显示高级选项',
  connecting: '连接中',
  loading: '加载中',
  topPlayer: '顶级玩家',
  newPlayer: '新玩家',

  // AI Players (for offline games)...
  aiSpeed: 'AI 玩家速度',
  slowSpeed: '慢',
  normalSpeed: '正常',
  fastSpeed: '快',
  instantSpeed: '瞬间',

  // Human player
  human: '人类',
  aiSafe: '安全',
  aiNormal: '正常',
  aiRisky: '冒险',
  aiCrazy: '疯狂',
  aiExtreme: '极端',

  // AI player
  ai: 'AI',
  humanFull: '{human}',
  aiSafeFull: '{ai}: {aiSafe}',
  aiNormalFull: '{ai}: {aiNormal}',
  aiRiskyFull: '{ai}: {aiRisky}',
  aiCrazyFull: '{ai}: {aiCrazy}',
  aiExtremeFull: '{ai}: {aiExtreme}',

  onlyInFull: '仅适用于 {fullEdition} 用户。',
  onlyInFullBrackets: '({fullEdition} 仅限)',

  // MenuPage
  welcomeToApp: '欢迎来到 {0}!',
  welcomeToAppDesc: '使用设置菜单更改你的用户名，如果你不确定如何玩，请使用帮助菜单。',
  freeJoinPublic:
    "更新：拥有 {basicEdition} 的玩家现在可以加入公共游戏！点击下面的 '{joinOnlineGame}'...",

  // Welcome message options
  hiUser1: '哦，嗨，{0}！',
  hiUser2: '欢迎，{0}！',
  hiUser3: '你好，{0}！',
  hiUser4: '嘿，{0}！',
  hiUser5: '你好呀，{0}！',
  // Button to create a new online game
  newOnlineGame: '创建在线游戏',
  // Button to join an existing online game
  joinOnlineGame: '加入在线游戏',
  // Button to start and play a new offline game
  newOfflineGame: '新离线游戏',
  // Confirming with the user that they want to start a new offline game when they already have an existing game
  newOfflineGameConfirm: '新离线游戏？',
  newOfflineGameConfirmDesc: '这将结束当前的离线游戏。{areYouSure}',
  // Button to continue playing an existing offline game
  continueOfflineGame: '继续离线游戏',

  // Button to take the user to their active games
  // {0} is the number of active games the user has
  yourXGames: '你的 {0} 游戏',
  yourXGames_plural: '你的 {0} 游戏',

  // Button to take the user to one specific game
  // {0} is the game ID
  goToRoom: '前往 #{0}',

  // Button to join a specific game (usually by invitation)
  // {0} is the game ID
  joinX: '加入 #{0}',

  // Button to go to the game rules
  howToPlay: '怎么玩',

  // Rules heading
  rules: '规则',
  // Rules intro lines
  rulesIntro1:
    '在每个回合，玩家掷四个骰子，然后将它们分成两个对，将每对相加。例如，一个玩家掷出 1、2、3 和 6，可以将它们分组为 5 和 7、4 和 8，或 3 和 9。',
  rulesIntro2: '选择一个配对以在相应的列上移动你的标记。每回合你只有有限数量的标记。',
  rulesIntro3:
    '如果没有可能的骰子配对，那么你的回合结束，标记将丢失。如果你选择停止，标记将被替换为你的颜色。',
  rulesIntro4: '2 和 12 列很短，但掷出这些值是罕见的，而 7 列是最长但最常见的。',
  rulesIntro5:
    '一旦玩家达到一列的顶部并停止，他们就会声称该列，并将其添加到他们的分数中。该列在游戏剩余时间内将不再被使用。一旦玩家达到目标分数，游戏结束。',

  // Online games with other people
  onlineGames: '在线游戏',
  onlineGamesDesc: '你可以通过创建自己的公共或私人游戏或加入现有游戏与其他玩家在线游戏。',

  // Offline games with friends in the same room or against AI
  offlineGames: '离线游戏（“传递并玩”）',
  offlineGamesDesc: '你可以用一个设备玩离线游戏，并在玩家之间传递该设备。',

  // Section about the original board game
  originalRules: '原始游戏',
  videoIntro:
    '"Can\'t Stop, Won\'t Stop" 灵感来自于原始桌游 "Can\'t Stop"，但与其没有任何关联。这里有几段 YouTube 视频解释原始桌游，以防你从未玩过：',
  // {0} is the number of the video (e.g. 1 => "Video 1")
  videoX: '视频 {0}',

  // Privacy Policy
  privacyPolicyDesc:
    '没有收集你的个人数据。你的应用设置和你玩的游戏被发送到服务器，但数据仅暂时保留，绝不会与任何第三方共享。添加你的电子邮件地址是可选的，仅用于恢复你的帐户。请发送电子邮件至 chris@mento.co.uk 请求删除你的数据。',

  probabilityCalculator: '概率计算器',
  probabilityCalculatorDesc:
    '我创建了一个小工具来帮助你理解掷出一个或多个列的概率。这可能会有所帮助！',

  // Game settings
  // {0} is the number of columns
  xColumns: '{0} 列',
  xColumns_plural: '{0} 列',
  xColumnsAltDesc: '获胜所需的列数。',
  // {0} is the number of columns
  xColumnsDesc: '第一个完成 {0} 列的玩家将赢得游戏。',
  xColumnsDesc_plural: '第一个完成 {0} 列的玩家将赢得游戏。',

  // {0} is the number of markers
  xMarkers: '{0} 标记',
  xMarkers_plural: '{0} 标记',
  xMarkersAltDesc: '每轮的最大列数。',
  // {0} is the number of columns
  xMarkersDesc: '每轮最多可以占用 {0} 列。',
  xMarkersDesc_plural: '每轮最多可以占用 {0} 列。',

  // Optional game mode 1
  forceMax: '强制最大标记',
  forceMaxDesc: '玩家必须选择使用最多新标记的骰子组合。',

  // Optional game mode 2
  preventSkip: '防止跳过回合',
  preventSkipDesc: '重新掷骰，使玩家永远不会失去整个回合。',

  // Optional game mode 3
  preDetermine: '预先确定下一个骰子掷出',
  preDetermineDesc: '在你点击 {continue} 或 {stop} 之前，随机掷出下一个骰子的数字。',

  // Optional game mode 4
  doubleHeight: '双高',
  doubleHeightDesc: '所有列的高度是原来的两倍，因此游戏时间更长。',

  // Public games can be seen and joined by anyone
  public: '公共',
  public_full: '公共游戏',
  publicDesc: '公共游戏可以被任何人看到并加入。',

  // Private games can only be joined using the room code
  private: '私人',
  private_full: '私人游戏',
  privateDesc: '私人游戏只能通过房间代码加入。',

  // Heading for the player types (which version of the app each player is using)
  userTypes: '玩家类型',

  // Game Info
  // {0} is the game ID
  currentGameX: '当前游戏 #{0}',
  // {0} is a relative time in the past, for example "5 minutes ago"
  lastActivityX: '最后活动 {0}',

  // For when a time is too recent to show the exact time
  justNow: '刚刚',

  // Alert message shown when the user presses the button to copy something to the clipboard
  gameIdCopied: '{gameId} 已复制到剪贴板！',
  websiteCopied: '网站已复制到剪贴板！',

  // Waiting
  // {0} is the number of players joined so far
  // {1} is the total number of players that are needed for the game to start
  xOfYPlayersJoined: '{0} / {1} 玩家已加入：',

  // Winning turn
  // {0} is the column number(s)
  // {1} is the Turn Progress percentage
  winningMoveSummaryShort: '{0} · {1}%',
  winningMoveSummaryLong: "通过完成列 {0} 结束，'回合进度' 为 {1}%。",

  // Full turn progress for the tooltip
  // {0} is the Turn Progress percentage
  turnProgressFull: "当前的 '回合进度' 是 {0}%",
  // If it was the previous player's turn:
  turnProgressLast: "之前的 '回合进度' 是 {0}%。",

  // Cancel button
  cancel: '取消',

  // Button to fully cancel/end the game
  cancelGame: '取消游戏',
  // Confirmation about cancelling the game before it starts
  cancelGameDesc: '这将取消所有人的游戏。你确定吗？',

  // Button to decline a rematch
  declineRematch: '拒绝复赛',
  // Button to view the previous game from a rematch
  viewPrevious: '查看上一局游戏',

  // Share App
  shareMessage: "🎲🎲 加入我并玩 Can't Stop，这个令人上瘾的骰子游戏！ 🎲🎲",
  // QR linked devices
  linkToWeb: '链接到网页',
  linkToWebDesc: '在 {webEdition} 上查看并玩您的游戏。',

  // Button to leave the game when waiting for other players to join
  leaveGame: '离开游戏',

  // Button to join a game
  joinGame: '加入游戏',
  // Button to join a rematch game
  joinRematch: '加入重赛',

  // {0} is the website URL
  inviteAtX: '邀请朋友在 {0} 免费加入',

  gameId: '游戏 ID',
  gameIdHash: '{gameId} #',

  // Join button
  join: '加入',
  enterGameId: '输入上面的 {gameId} 以加入朋友的私人游戏。',
  // {0} is the name of the app
  listGamesFullApp: '如果您想查看公共游戏列表，您需要 {0} 应用程序：',
  noPublicGames: '目前没有可加入的公共游戏。',
  newGameFullApp:
    '为什么不创建一个新游戏并邀请您的朋友免费加入（使用 {basicEdition} 应用或 {webEdition}）？',
  publicGames: '公共游戏',
  waiting: '等待',
  publicGamesWaiting: '{publicGames}: {waiting}',
  inProgress: '进行中',
  publicGamesInProgress: '{publicGames}: {inProgress}',
  recentlyFinished: '最近完成',
  publicGamesRecentlyFinished: '{publicGames}: {recentlyFinished}',

  // New Game
  createOnlineGame: '创建在线游戏',
  createOnlineGameDesc: '创建一个在线游戏，然后邀请您的朋友或等待其他玩家加入！',
  createOfflineGame: '创建离线游戏',
  createOfflineGameDesc: '创建一个“传递与玩”的离线游戏，与同一房间的朋友玩或只是练习。',
  // {0} is the name of the app
  noNewGameWeb: '{0} 的网页版仅允许您通过 ID 加入游戏。如果您想创建新游戏，您需要应用程序：',
  // {0} is the name of the app
  noNewGameBasic:
    '{0} 的基础版仅允许您加入游戏并创建离线游戏。如果您想创建新在线游戏，您需要无广告的完整应用程序：',
  basicLimitMessage:
    '{0} 的基础版仅允许您同时创建五个游戏，并具有有限的设置。如果您想更改选项或创建更多游戏，您需要无广告的完整应用程序：',
  // {0} is the number of players
  xPlayers: '{0} 玩家',
  xPlayersDesc: '游戏中的玩家数量。',

  // Conceding
  concede: '认输',
  conceded: '已认输',
  xConceded: '{0} 认输。',
  youConceded: '您认输。',
  xGamesConceded: '{0} 场认输（{1}%）',
  xGamesConceded_plural: '{0} 场认输（{1}%）',

  // Games played
  xGamesPlayed: '{0} 场完成的游戏',
  xGamesPlayed_plural: '{0} 场完成的游戏',
  xGamesPlaying: '{0} 场进行中的游戏',
  xGamesPlaying_plural: '{0} 场进行中的游戏',
  // xStopRate: '最近: {1}% / 总体: {0}%',
  xStopRate: '{0} 的停止率',
  xStopRateDesc: '(以停止结束的回合)',

  vsFormDesc: '您最近与 {0} 的结果',

  recent: '最近',
  allTime: '所有时间',

  concedeCheck: '您确定要认输这场游戏吗？这无法撤消，并且可能会影响您的分数/状态。',

  // Rematch
  rematch: '重赛',
  invited: '已邀请',
  rematchProposed: '重赛提议',

  // Replay from here button
  replay: '从这里重播',
  // Button to stop the replay
  stopReplay: '停止重播',

  // Recent form: Win
  formWin: '胜利',
  // Recent form: Loss
  formLoss: '失败',
  // Recent form: Conceded
  formConceded: '认输',

  recentForm: '{0} 的近期状态',

  // Game Modes

  // The classic normal rules of the game
  classic: '经典',
  classicFull: '经典模式',
  classicDesc: '经典规则。',

  // The "blocking" variant of the game
  block: '阻挡',
  blockFull: '"阻挡" 变体',
  blockDesc: '在与其他玩家相同的位置停止会被阻挡。',

  // The "Jumping" variant of the game
  jump: '跳跃',
  jumpFull: '"跳跃" 变体',
  jumpDesc: '自动跳过其他玩家，以确保玩家永远不在同一位置。',

  // Game
  youWin: '你赢了！',
  youWon: '您赢了！',
  // {0} is the winning player name
  xWins: '{0} 赢了！',
  xWon: '{0} 赢了',
  youLost: '你输了',
  xWinRate: '{0}% 胜率',
  neither: '都不是',

  // Alert when there are no more possible moves
  noPossibleMoves: '没有可能的移动',

  // Button to stop and pass to the next player
  stop: '停止',

  gameOver: '游戏结束！',
  // Button to start a new game
  newGame: '新游戏',
  // Notification and history
  gameStarted: '游戏开始了！',
  // Button to end the game early
  endGame: '结束游戏',
  startGame: '开始游戏',
  nextGame: '下一局游戏',

  // Confirmation about ending the game early
  endGameEarly: '提前结束游戏？',
  endGameEarlyDesc: '这将结束当前游戏，所有人都将结束。您确定吗？',

  yourTurn: '轮到您了',
  // {0} is the player's name
  xTurn: '{0} 的回合',
  // The status of a finished game
  finished: '完成',
  // The status of a game in progress
  playing: '正在进行',
  // {0} is the number of players
  waitingForX: '等待 {0}',
  // {0} is the number of players
  waitingForXPlayers: '等待 {0} 位玩家',
  waitingForXPlayers_plural: '等待 {0} 位玩家',
  // {0} is a relative time in the past, for example "2 days ago"
  createdX: '创建于 {0}',

  // New version
  newVersion: '新版本可用',
  newVersionDesc: '版本 {0} 现在可以下载。',

  // Beta Tester
  becomeTester: '成为测试人员？',
  becomeTesterDesc: '如果您想提前尝试新功能，请通过电子邮件联系我以加入志愿测试人员的群体！',
  // Button to decline joining as a tester
  noThanks: '不，谢谢！',

  // Warning message when push notification permissions are denied
  pushDeniedTitle: '推送通知当前已关闭。',
  pushDeniedDesc: '关闭后，你将无法收到轮到你的通知，并且有自动放弃游戏的风险。',

  // Settings
  settings: '设置',
  // Button to set/save the player's username
  set: '设置',
  // To change if the app will make sounds or not
  sounds: '声音',
  // The player's name visible to others online
  username: '用户名',
  // The app's language
  language: '语言',
  // Lock screen awake
  lockScreen: '在游戏期间保持屏幕常亮',
  // Player colour settings
  playerColours: '玩家颜色',
  // emoji tone
  emojiTone: '表情符号音调',

  // Button to show more of the history moves
  showMore: '显示更多',

  // User Key (for switching accounts)
  userKey: '用户账户密钥',
  userKeyDesc: '警告：仅在你知道自己在做什么的情况下使用。不要与任何人分享。',

  // Send email requesting deletion of user account
  requestDeleteAccount: '请求删除账户',

  // Show last turn in the display
  lastTurnSetting: '可视化每位玩家的上一个回合',

  // Turn Progress toggle
  turnProgressSetting: "在游戏中显示 '回合进度'",
  turnProgressSettingDesc:
    "'回合进度' 是当前列完成的百分比。例如，如果你设法在一个回合中从头开始完成所有 3 列，它将显示 100%。开启后，它将在游戏屏幕的右上角、历史面板和重赛屏幕中显示。",

  // *** Keyboard Shortcuts ***

  // Turn on/off keyboard shortcuts for games
  keyShortcutsSetting: '键盘快捷键',

  // Spacebar key
  keySpace: '空格',

  // Ctrl key
  keyCtrl: 'Ctrl',

  // Shift key
  keyShift: 'Shift',

  // "Next" action
  keyActionNextDesc: '"下一步"（继续，下一房间或单骰选项）。',
  keyActionNextNote: '注意：当有 "下一步" 按钮时，它将有一个边框。',

  // Dice option
  keyActionNumDesc: '选择那个数字的骰子选项。',
  keyActionNumNote: '注意：如果 10-12 只有一个选项，你只需按 1。',

  // Dice position
  keyActionCtrlNumDesc: '选择该位置的骰子选项。',
  // {0} is Ctrl (or another key)
  keyActionCtrlNumNote: '例如 {0}+1 是第一个选项',

  // Action to open the keyboard shortcuts instructions
  keyActionOpenHelpDesc: '打开这些说明。',

  includeLostGames: '在下一场游戏中包括失去的游戏',
  includeLostGamesDesc: '‘转到 #’ 按钮（将你带到下一步）将包括你最近失去的游戏。',

  you: '你',
  // Your Games
  yourGames: '你的游戏',
  notInGames: '你当前没有任何正在进行的游戏。',

  // Errors
  waitingLimit: '你已经达到等待开始的新游戏的限制。',
  basicLimit: '基础版 {basicEdition} 仅允许你同时创建五个游戏。',
  serverTimeout: '服务器超时。请重试。',
  samePlayersLimit: '你已达到与相同玩家的活动游戏数量限制。',

  // Bot check
  typeOk: '请在下面输入“ok”以继续...',

  // Error page
  somethingWrong: '发生了错误！',
  somethingWrongDesc: '抱歉！请给我发电子邮件，附上以下信息和你正在做的简要描述，我会尽快解决：',

  // Common app localisations:
  privacyPolicy: '隐私政策',
  // Link to support the developer
  supportDeveloper: '请开发者喝杯咖啡',

  // Button to say no
  no: '不',
  // Button to say yes
  yes: '是',
  // Close/exit button
  close: '关闭',
  // Button to continue playing
  continue: '继续',
  comingSoon: '敬请期待！',
  reset: '重置',
  ok: '好的',

  // The app's colour theme (light/dark)
  appTheme: '应用主题',
  appThemeSystem: '系统默认',
  appThemeLight: '明亮模式',
  appThemeDark: '黑暗模式',

  // *** User Account ***
  signedInAsX: '已登录为 {0}',
  signedInDesc: '你可以从其他设备登录。',
  signOut: '注销',
  notSignedIn: '未登录。',
  signInToSave: '登录以保存你的玩家账户和设置。你可以从其他设备登录！',
  signInNew:
    '新用户？注册以保存你的玩家账户和设置。你可以从其他设备登录！如果你已经有账户，请在下面登录。',
  signInRegister: '登录 / 注册',

  // The 3 below are joined together to form one message with the middle as a button
  guestModeWarning1: '你正在使用游客模式。',
  guestModeWarning2Btn: '登录',
  guestModeWarning3: '以保护你的账户并在其他设备上玩。',

  // Screenshot text
  // Each screenshot has text above and text below, which is why each of the below are in 2:
  screenshotPlayAgainst: '"不能停止" 骰子游戏',
  screenshotPlayAgainst2: '与朋友对战，在线与离线',
  screenshotCreateOnline: '创建在线游戏',
  screenshotCreateOnline2: '包括“跳跃”和“阻挡”变体！',
  screenshotPlayFriends: '与朋友玩',
  screenshotPlayFriends2: '最多 4 位玩家在线！',
  screenshotJoinPublicPrivate: '加入公共或私人游戏',
  screenshotJoinPublicPrivate2: '也可以与陌生人对战！',
  screenshotOffline: '传递并玩',
  screenshotOffline2: '与朋友一起玩离线游戏',
  screenshotInvite: '邀请朋友免费玩',
  screenshotInvite2: '使用基础版应用或网络版',
  screenshotLimitedFree: '基础版的功能有限',
  screenshotLimitedFree2: '有广告，不支持创建在线游戏。',
  screenshotSettings: '更改设置',
  screenshotSettings2: '包括明亮和黑暗模式',

  // App listing
  appStoreNameFull: '不能停止：骰子游戏', // Maximum 50 characters
  appStoreSubtitleFull: '与朋友一起玩，掷骰子！', // Maximum 80 characters
  appStoreDescriptionFull:
    '基于“不能停止”桌游的全功能流行骰子游戏。在线或离线（传递和玩）。2-4 位玩家。\n\n尝试不同的变体或坚持经典版本！\n\n你的朋友可以加入你的游戏，即使他们没有应用程序。创建新游戏和玩离线游戏需要应用程序。',

  appStoreNameBasic: '不能停止：骰子游戏（基础版）', // Maximum 50 characters
  appStoreSubtitleBasic: '免费与朋友一起掷骰子！', // Maximum 80 characters
  appStoreDescriptionBasic:
    '这是基于“不能停止”桌游的流行骰子游戏的基础版。在线或离线（传递和玩）。2-4 位玩家。\n\n尝试不同的变体或坚持经典版本！\n\n完整应用程序是创建新在线游戏所需的。',
};

export default zh;
