import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import * as ls from 'local-storage';
import AppWrapper from './AppWrapper';
import * as serviceWorker from './serviceWorker';

// import { setConfig } from 'react-hot-loader';
import ErrorBoundary from './ErrorBoundary';
// import { consoleLog } from './utils';
// import { errorMsgs } from './utils';

declare var window: any;
// import { isAndroid } from './utils';
// setConfig({ errorReporter: ErrorBoundary });

// let banner;

// const setFirebaseStuff = () => {
//   /* Check Plugins */
//   if (window.cordova.plugins === undefined) {
//     window.cordova.plugins = {};
//   }

//   /* Browsertab */
//   window.cordova.plugins.browsertab = {};

//   /* Browsertab - Is Available */
//   window.cordova.plugins.browsertab.isAvailable = function (success: any) {
//     window.SafariViewController.isAvailable(success);
//   };

//   /* Browsertab - Open Url */
//   window.cordova.plugins.browsertab.openUrl = function (url: string) {
//     window.SafariViewController.show(
//       { url: url },
//       function () {},
//       function () {},
//     );
//   };

//   /* Browsertab - Close */
//   window.cordova.plugins.browsertab.close = function () {
//     window.SafariViewController.hide();
//   };
// };
// setFirebaseStuff();

try {
  // consoleLog('Trying to render Cordova app');
  if (!window.cordova) {
    ReactDOM.render(
      <React.StrictMode>
        <ErrorBoundary>
          <AppWrapper />
        </ErrorBoundary>
      </React.StrictMode>,
      document.getElementById('root'),
    );
  } else {
    // consoleLog('Cordova detected');
    document.addEventListener(
      'deviceready',
      () => {
        // consoleLog('deviceready');
        if (typeof navigator !== 'undefined' && typeof navigator.splashscreen !== 'undefined') {
          navigator.splashscreen.hide();
        }

        ReactDOM.render(
          <React.StrictMode>
            <ErrorBoundary>
              <AppWrapper />
            </ErrorBoundary>
          </React.StrictMode>,
          document.getElementById('root'),
        );
        // ls.waitForCache(() => {
        //   // get SQLite data first
        //   errorMsgs.push('waitForCache DONE');
        // });
        // try {
        //   await admob.start();
        //   banner = new admob.BannerAd({
        //     adUnitId: isAndroid()
        //       ? 'ca-app-pub-2706311467290353/3136032059'
        //       : 'ca-app-pub-2379142366574646/4090560077',
        //   });

        //   await banner.show();
        // } catch (e) {
        //   console.log('admob error', e);
        // }
      },
      false,
    );
  }
} catch (error) {
  // consoleLog(`Error rendering Cordova app: ${JSON.stringify(error)}`);
  ReactDOM.render(<div>{JSON.stringify(error)}</div>, document.getElementById('root'));
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
