import LocalizedStrings from 'react-localization';
import browserLang from 'browser-lang';
import * as ls from 'local-storage';
import en from './locs/en';
import ja from './locs/ja';
import es from './locs/es';
import zh from './locs/zh';
import de from './locs/de';
import fr from './locs/fr';
import pt from './locs/pt';

// interface ExtStrings extends LocalizedStringsMethods {
//   format: (k: string, ...p: any[]) => string | any[];
// }

// type ExtLocalizedStrings<T> = LocalizedStringsMethods & T;

const supportedLanguages = {
  en,
  ja,
  es,
  zh,
  de,
  fr,
  pt,
};
export const getSystemLanguage = () => {
  const myLanguage = browserLang({
    languages: Object.keys(supportedLanguages),
    fallback: 'en',
  });
  // console.log('myLanguage', myLanguage);
  return myLanguage;
};
// public strings: IStrings;
const locO = new LocalizedStrings(
  supportedLanguages,
  ls.get<string>('language')
    ? {
        customLanguageInterface: () => {
          const lang = ls.get<string>('language') || 'system';

          if (lang === 'system') {
            // get the system language using LanguageDetector from i18next
            return getSystemLanguage();
          }
          return lang;
        },
        logsEnabled: false,
      }
    : { logsEnabled: false },
);

const getStringsByLanguage = (language: string) => {
  switch (language) {
    case 'en':
      return en;
    case 'ja':
      return ja;
    case 'es':
      return es;
    case 'zh':
      return zh;
    case 'de':
      return de;
    case 'fr':
      return fr;
    case 'pt':
      return pt;

    default:
      return en;
  }
};

const loc = {
  ...locO,
  format: (k: string, ...p: any[]) => {
    try {
      // console.log('loc try', k, typeof p);
      if (typeof p === 'undefined' || !Array.isArray(p) || p.length === 0) {
        return locO.formatString(locO.getString(k), getStringsByLanguage(locO.getLanguage()));
      }
      return typeof p !== 'undefined' &&
        Array.isArray(p) &&
        typeof p[0] === 'number' &&
        p[0] !== 1 &&
        locO.getString(`${k}_plural`)
        ? locO.formatString(locO.getString(`${k}_plural`), ...p)
        : locO.formatString(locO.getString(k), ...p);
    } catch (e) {
      console.log('loc error', k, p, e);
      return '?';
    }
  },
  formatString: locO.formatString,
  getString: locO.getString,
  getLanguage: locO.getLanguage,
  setLanguage: locO.setLanguage,
  getLocFn: () => locO,
  // loc: locO,
};

export default loc;
