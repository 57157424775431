// Every string is in the form:
// key: 'String text',
// The key is in light blue and the string text is in peach.
// Only ever translate the string text in a PEACH colour.
// Also don't edit the {variables} in curly brackets (see below for more info)

// Most strings have green comments on the line above to hopefully help explain and give context.

// *** {0}, {1}, etc ***
// These are variables inserted into the text. The variables will be explained in the line(s) above.

// *** {namedStrings} ***
// There are using other strings from the rest of the file.
// e.g. 'The app is {appName}!' would result in "The app is Can't Stop, Won't Stop"
// If the use of that string doesn't actually work in the local language you can
// just remove the "{namedString}" and write out the full text.

// *** _plural ***
// In English and many other languages there are singular and plural forms of words, such as "1 cat" vs "2 cats".
// When a variable (like {0}) is a number, sometimes there needs to be two strings, such as:
// xCats: '{0} cat', (when {0} is 1)
// xCats_plural: '{0} cats', (when {0} is anything other than 1)
// If the new language doesn't have plurals, you can just delete the lines where the key ends with "_plural".

const fr = {
  lang: 'fr',
  appStoreLang: 'fr',
  playStoreLang: 'fr',
  dateAt: ' à ',
  // App Name
  appName: "Can't Stop, Won't Stop",

  // Version change log title
  whatsNewInX: `Nouveautés dans {0}`,

  // Alert in the menu if there's no user data, asking if their account is missing
  recoveryFailedTitle: 'Vos jeux ont-ils disparu ?',
  recoveryFailedDesc:
    "Si vos jeux ont disparu après l'installation d'une mise à jour, ne vous inquiétez pas ! Envoyez-moi un e-mail avec votre nom d'utilisateur précédent et je vous remettrai en marche.",

  // General
  // Question confirming the user wants to do the action
  areYouSure: 'Êtes-vous sûr ?',
  notConnected: "Vous n'êtes pas actuellement connecté au serveur.",
  // Email button
  emailMe: 'Envoyez-moi un email',
  // Email Address text field
  emailAddress: 'Adresse e-mail',
  // Helper text for privacy of email
  emailHelper: 'Votre adresse e-mail sera uniquement utilisée pour récupérer votre compte.',
  // When a user is also an admin
  admin: 'Administrateur',
  // The full paid version of the app
  fullEdition: 'Premium',
  // The limited free version of the app
  basicEdition: 'Édition Basique',
  // The website version of the app
  webEdition: 'Édition Web',
  // An unknown type of user
  unknownUser: 'Utilisateur Inconnu',
  // Not connected to the internet
  offline: 'Hors ligne',
  offlineGame: 'Jeu Hors Ligne',
  offlineGameDesc:
    'Un jeu hors ligne "Pass and Play" à jouer avec des amis dans la même pièce ou simplement pour s\'entraîner contre l\'IA.',
  // Button for downloading on the Apple App Store
  downloadAppStore: "Télécharger sur l'App Store",
  // Button for downloading on the Google Play Store
  downloadPlayStore: 'Obtenez-le sur Google Play',
  // Button to expand the form and show the more advanced options
  showAdvanced: 'Afficher Avancé',
  // Displayed when the app is connecting to the game server
  connecting: 'Connexion',
  // Displayed when the app is loading a game or page
  loading: 'Chargement',
  // A star is shown next to players with the best scores
  topPlayer: 'Meilleur Joueur',
  // An icon is shown next to new players
  newPlayer: 'Nouveau Joueur',

  // AI Players (for offline games)...
  // The speed of the AI player
  aiSpeed: 'Vitesse du Joueur IA',
  // The slowest AI player
  slowSpeed: 'Lent',
  // The normal speed AI player
  normalSpeed: 'Normal',
  // The fast speed AI player
  fastSpeed: 'Rapide',
  // The fastest AI player, where moves are instant
  instantSpeed: 'Instantané',

  // Human player
  human: 'Humain',
  // AI player risk level 1: Safe
  aiSafe: 'Prudent',
  // AI player risk level 2: Normal
  aiNormal: 'Normal',
  // AI player risk level 3: Risky
  aiRisky: 'Risqué',
  // AI player risk level 4: Crazy
  aiCrazy: 'Fou',
  // AI player risk level 5: Extreme
  aiExtreme: 'Extrême',

  // AI player
  ai: 'IA',
  humanFull: '{human}',
  aiSafeFull: '{ai} : {aiSafe}',
  aiNormalFull: '{ai} : {aiNormal}',
  aiRiskyFull: '{ai} : {aiRisky}',
  aiCrazyFull: '{ai} : {aiCrazy}',
  aiExtremeFull: '{ai} : {aiExtreme}',

  // Note for when a feature is only available in the full edition:
  onlyInFull: 'Disponible uniquement pour les utilisateurs de {fullEdition}.',
  // The same as above but in brackets and shorter
  onlyInFullBrackets: '({fullEdition} uniquement)',

  // MenuPage
  // {0} is the name of the app in bold
  welcomeToApp: 'Bienvenue sur {0} !',
  // Welcome intro
  welcomeToAppDesc:
    "Utilisez le menu des paramètres pour changer votre nom d'utilisateur et le menu d'aide si vous ne savez pas comment jouer.",
  freeJoinPublic:
    "MISE À JOUR : Les joueurs avec l'{basicEdition} peuvent désormais rejoindre des jeux publics ! Appuyez sur '{joinOnlineGame}' ci-dessous...",

  // Welcome message options
  // {0} is the username
  hiUser1: 'Oh salut, {0} !',
  hiUser2: 'Bienvenue, {0} !',
  hiUser3: 'Salut, {0} !',
  hiUser4: 'Hey, {0} !',
  hiUser5: 'Bonjour, {0} !',

  // Button to create a new online game
  newOnlineGame: 'Créer un Jeu en Ligne',
  // Button to join an existing online game
  joinOnlineGame: 'Rejoindre un Jeu en Ligne',
  // Button to start and play a new offline game
  newOfflineGame: 'Nouveau Jeu Hors Ligne',
  // Confirming with the user that they want to start a new offline game when they already have an existing game
  newOfflineGameConfirm: 'Nouveau Jeu Hors Ligne ?',
  newOfflineGameConfirmDesc: 'Cela mettra fin au jeu hors ligne actuel. {areYouSure}',
  // Button to continue playing an existing offline game
  continueOfflineGame: 'Continuer le Jeu Hors Ligne',

  // Button to take the user to their active games
  // {0} is the number of active games the user has
  yourXGames: 'Votre Partie',
  yourXGames_plural: 'Vos {0} Parties',

  // Button to take the user to one specific game
  // {0} is the game ID
  goToRoom: 'Aller à #{0}',

  // Button to join a specific game (usually by invitation)
  // {0} is the game ID
  joinX: 'Rejoindre #{0}',

  // Button to go to the game rules
  howToPlay: 'Comment jouer',

  // Rules heading
  rules: 'Règles',
  // Rules intro lines
  rulesIntro1:
    'À chaque tour, le joueur lance les quatre dés, puis les divise en deux paires, en additionnant chaque paire. Par exemple, un joueur lançant un 1, 2, 3 et 6 pourrait les grouper en 5 & 7, 4 & 8, ou 3 & 9.',
  rulesIntro2:
    "Choisissez un regroupement pour déplacer vos marqueurs sur les colonnes correspondantes. Vous n'avez qu'un nombre limité de marqueurs par tour.",
  rulesIntro3:
    "S'il n'y a pas de regroupements possibles avec les dés, votre tour se termine et les marqueurs sont perdus. Si vous choisissez d'arrêter, vos marqueurs seront remplacés par votre couleur.",
  rulesIntro4:
    'Les colonnes 2 et 12 sont courtes mais il est rare de les obtenir avec les dés, tandis que la colonne 7 est la plus longue mais la plus commune.',
  rulesIntro5:
    "Une fois qu'un joueur atteint le sommet d'une colonne et s'arrête, il revendique la colonne et elle est ajoutée à son score. Cette colonne ne peut plus être jouée pour le reste du jeu. Une fois qu'un joueur atteint le score cible, la partie se termine.",

  // Online games with other people
  onlineGames: 'Jeux en Ligne',
  onlineGamesDesc:
    "Vous pouvez jouer en ligne contre d'autres joueurs en créant votre propre jeu public ou privé ou en rejoignant un jeu existant.",

  // Offline games with friends in the same room or against AI
  offlineGames: 'Jeux Hors Ligne ("Pass and Play")',
  offlineGamesDesc:
    "Vous pouvez jouer à un jeu hors ligne avec un appareil et passer l'appareil entre les joueurs.",

  // Section about the original board game
  originalRules: 'Jeu Original',
  videoIntro:
    "\"Can't Stop, Won't Stop\" est inspiré du jeu de société original, \"Can't Stop\", mais n'y est en aucun cas affilié. Voici quelques vidéos YouTube expliquant le jeu de société original, au cas où vous ne l'auriez jamais joué :",
  // {0} is the number of the video (e.g. 1 => "Video 1")
  videoX: 'Vidéo {0}',

  // Privacy Policy
  privacyPolicyDesc:
    "Aucune de vos données personnelles n'est collectée. Vos paramètres d'application et les jeux auxquels vous jouez sont envoyés à un serveur, mais les données ne sont conservées que temporairement et ne sont jamais partagées avec un tiers. L'ajout de votre adresse e-mail est facultatif et n'est utilisé que pour récupérer votre compte. Envoyez un e-mail à chris@mento.co.uk pour demander que vos données soient supprimées.",

  probabilityCalculator: 'Calculateur de Probabilités',
  probabilityCalculatorDesc:
    "J'ai créé un petit outil pour vous aider à comprendre les probabilités de faire des colonnes. Cela pourrait vous aider !",

  // Game settings
  // {0} is the number of columns
  xColumns: '{0} Colonne',
  xColumns_plural: '{0} Colonnes',
  xColumnsAltDesc: 'Nombre de colonnes nécessaires pour gagner.',
  // {0} is the number of columns
  xColumnsDesc: 'Le premier joueur à compléter {0} colonne gagnera la partie.',
  xColumnsDesc_plural: 'Le premier joueur à compléter {0} colonnes gagnera la partie.',

  // {0} is the number of markers
  xMarkers: '{0} Marqueur',
  xMarkers_plural: '{0} Marqueurs',
  xMarkersAltDesc: 'Nombre maximum de colonnes par tour.',
  // {0} is the number of columns
  xMarkersDesc: 'Vous pouvez occuper au maximum {0} colonne par tour.',
  xMarkersDesc_plural: 'Vous pouvez occuper au maximum {0} colonnes par tour.',

  // Optional game mode 1
  forceMax: 'Forcer les marqueurs maximum',
  forceMaxDesc:
    'Le joueur doit choisir une combinaison de dés qui utilise le plus de nouveaux marqueurs.',

  // Optional game mode 2
  preventSkip: 'Empêcher les tours sautés',
  preventSkipDesc: "Relancez les dés pour qu'un joueur ne perde jamais son tour entier.",

  // Optional game mode 3
  preDetermine: 'Pré-déterminer le prochain lancer de dés',
  preDetermineDesc:
    "Les prochains numéros de dés sont lancés au hasard avant que vous n'appuyiez sur {continue} ou {stop}.",

  // Optional game mode 4
  doubleHeight: 'Double Hauteur',
  doubleHeightDesc: 'Toutes les colonnes ont une double hauteur pour des parties plus longues.',

  // Public games can be seen and joined by anyone
  public: 'Public',
  public_full: 'Partie Publique',
  publicDesc: "Les parties publiques peuvent être vues et rejointes par n'importe qui.",

  // Private games can only be joined using the room code
  private: 'Privé',
  private_full: 'Partie Privée',
  privateDesc: "Les parties privées ne peuvent être rejointes qu'avec le code de la salle.",

  // Heading for the player types (which version of the app each player is using)
  userTypes: 'Types de Joueurs',

  // Game Info
  // {0} is the game ID
  currentGameX: 'Partie en cours #{0}',
  // {0} is a relative time in the past, for example "5 minutes ago"
  lastActivityX: 'Dernière activité {0}',

  // For when a time is too recent to show the exact time
  justNow: "à l'instant",

  // Alert message shown when the user presses the button to copy something to the clipboard
  gameIdCopied: '{gameId} copié dans le presse-papier !',
  websiteCopied: 'Site Web copié dans le presse-papier !',

  // Waiting
  // {0} is the number of players joined so far
  // {1} is the total number of players that are needed for the game to start
  xOfYPlayersJoined: '{0} sur {1} joueurs ont rejoint :',

  // Winning turn
  // {0} is the column number(s)
  // {1} is the Turn Progress percentage
  winningMoveSummaryShort: '{0} · {1}%',
  winningMoveSummaryLong:
    "Terminé en complétant la colonne {0} avec un 'Progression du Tour' de {1}%",

  // Full turn progress for the tooltip
  // {0} is the Turn Progress percentage
  turnProgressFull: "La 'Progression du Tour' actuelle est de {0}%",
  // If it was the previous player's turn:
  turnProgressLast: "La 'Progression du Tour' précédente était de {0}%",

  // Cancel button
  cancel: 'Annuler',

  // Button to fully cancel/end the game
  cancelGame: 'Annuler la Partie',
  // Confirmation about cancelling the game before it starts
  cancelGameDesc: 'Cela annulera la partie pour tout le monde. Êtes-vous sûr ?',

  // Button to decline a rematch
  declineRematch: 'Refuser la Revanche',
  // Button to view the previous game from a rematch
  viewPrevious: 'Voir la Partie Précédente',

  // Share App
  shareMessage: "🎲🎲 Rejoins-moi et joue à Can't Stop, le jeu de dés addictif ! 🎲🎲",

  // QR linked devices
  linkToWeb: 'Lien vers le Web',
  linkToWebDesc: 'Voir et jouer à vos parties sur {webEdition}.',

  // Button to leave the game when waiting for other players to join
  leaveGame: 'Quitter la Partie',

  // Button to join a game
  joinGame: 'Rejoindre la Partie',
  // Button to join a rematch game
  joinRematch: 'Rejoindre la Revanche',

  // {0} is the website URL
  inviteAtX: 'Invitez vos amis à rejoindre gratuitement sur {0}',

  gameId: 'ID de Partie',
  gameIdHash: '{gameId} #',

  // Join button
  join: 'Rejoindre',
  enterGameId: "Saisissez l'{gameId} ci-dessus pour rejoindre la partie privée d'un ami.",
  // {0} is the name of the app
  listGamesFullApp:
    "Si vous voulez pouvoir voir une liste de parties publiques, vous aurez besoin de l'application {0} :",
  noPublicGames: "Il n'y a actuellement aucune partie publique à rejoindre.",
  newGameFullApp:
    "Pourquoi ne pas créer une nouvelle partie et inviter vos amis à vous rejoindre gratuitement (avec l'application {basicEdition} ou {webEdition}) ?",
  publicGames: 'Parties Publiques',
  waiting: 'En Attente',
  publicGamesWaiting: '{publicGames} : {waiting}',
  inProgress: 'En Cours',
  publicGamesInProgress: '{publicGames} : {inProgress}',
  recentlyFinished: 'Récemment Terminées',
  publicGamesRecentlyFinished: '{publicGames} : {recentlyFinished}',

  // New Game
  createOnlineGame: 'Créer une Partie en Ligne',
  createOnlineGameDesc:
    "Créez une partie en ligne et invitez ensuite vos amis ou attendez que d'autres joueurs rejoignent !",
  createOfflineGame: 'Créer une Partie Hors-Ligne',
  createOfflineGameDesc:
    "Créez une partie 'Passer et Jouer' hors-ligne pour jouer avec des amis dans la même pièce ou simplement pour vous entraîner.",
  // {0} is the name of the app
  noNewGameWeb:
    "La version Web de {0} vous permet uniquement de rejoindre des parties avec leur ID. Si vous voulez créer de nouvelles parties, vous aurez besoin de l'application :",
  // {0} is the name of the app
  noNewGameBasic:
    "La version Basique de {0} vous permet uniquement de rejoindre des parties et de créer des parties hors-ligne. Si vous voulez créer une nouvelle partie en ligne, vous aurez besoin de l'application complète sans publicité :",
  basicLimitMessage:
    "La version Basique de {0} vous permet de créer cinq parties à la fois avec des paramètres limités. Si vous voulez changer les options ou créer plus de parties, vous aurez besoin de l'application complète sans publicité :",
  // {0} is the number of players
  xPlayers: '{0} Joueurs',
  xPlayersDesc: 'Nombre de joueurs dans la partie.',

  // Conceding
  concede: 'Abandonner',
  conceded: 'Abandonné',
  xConceded: '{0} a abandonné.',
  youConceded: 'Vous avez abandonné.',
  xGamesConceded: '{0} partie abandonnée ({1}%)',
  xGamesConceded_plural: '{0} parties abandonnées ({1}%)',

  // Games played
  xGamesPlayed: '{0} partie terminée',
  xGamesPlayed_plural: '{0} parties terminées',
  xGamesPlaying: '{0} partie en cours',
  xGamesPlaying_plural: '{0} parties en cours',
  // xStopRate: 'Recent: {1}% / Overall: {0}%',
  xStopRate: "Taux d'Arrêt de {0}",
  xStopRateDesc: '(tours se terminant par un arrêt)',

  vsFormDesc: 'Vos résultats récents contre {0}',

  recent: 'Récent',
  allTime: 'Tout le Temps',

  concedeCheck:
    'Êtes-vous sûr de vouloir abandonner cette partie ? Cela ne peut pas être annulé et cela peut affecter votre score/statut.',

  // Rematch
  rematch: 'Revanche',
  invited: 'Invité',
  rematchProposed: 'Revanche Proposée',

  // Replay from here button
  replay: 'Rejouer Depuis Ici',
  // Button to stop the replay
  stopReplay: 'Arrêter la lecture',

  // Recent form: Win
  formWin: 'Victoire',
  // Recent form: Loss
  formLoss: 'Défaite',
  // Recent form: Conceded
  formConceded: 'Concédé',

  recentForm: 'Forme récente de {0}',

  // Game Modes

  // The classic normal rules of the game
  classic: 'Classique',
  classicFull: 'Mode Classique',
  classicDesc: 'Les règles classiques.',

  // The "blocking" variant of the game
  block: 'Blocage',
  blockFull: 'Variante "Blocage"',
  blockDesc: 'S’arrêter aux mêmes positions qu’un autre joueur est bloqué.',

  // The "Jumping" variant of the game
  jump: 'Saut',
  jumpFull: 'Variante "Saut"',
  jumpDesc:
    'Sauter automatiquement les autres joueurs pour qu’ils ne soient jamais à la même position.',

  // Game
  youWin: 'Vous avez gagné !',
  youWon: 'Vous avez gagné !',
  // {0} is the winning player name
  xWins: '{0} gagne !',
  xWon: '{0} a gagné',
  youLost: 'Vous avez perdu',
  xWinRate: 'Taux de victoire de {0} %',
  neither: 'Aucun',

  // Alert when there are no more possible moves
  noPossibleMoves: 'Aucun mouvement possible',

  // Button to stop and pass to the next player
  stop: 'Arrêter',

  gameOver: 'Fin du jeu !',
  // Button to start a new game
  newGame: 'Nouvelle partie',
  // Notification and history
  gameStarted: 'Partie commencée !',
  // Button to end the game early
  endGame: 'Terminer la partie',
  startGame: 'Démarrer la partie',
  nextGame: 'Partie suivante',

  // Confirmation about ending the game early
  endGameEarly: 'Terminer la partie tôt ?',
  endGameEarlyDesc: 'Cela mettra fin à la partie actuelle pour tout le monde. Êtes-vous sûr ?',

  yourTurn: 'À votre tour',
  // {0} is the player's name
  xTurn: 'Tour de {0}',
  // The status of a finished game
  finished: 'Terminé',
  // The status of a game in progress
  playing: 'En cours',
  // {0} is the number of players
  waitingForX: 'En attente de {0}',
  // {0} is the number of players
  waitingForXPlayers: 'En attente de {0} joueur',
  waitingForXPlayers_plural: 'En attente de {0} joueurs',
  // {0} is a relative time in the past, for example "2 days ago"
  createdX: 'Créé {0}',

  // New version
  newVersion: 'Nouvelle version disponible',
  newVersionDesc: 'Version {0} est prête à être téléchargée.',

  // Beta Tester
  becomeTester: 'Devenir testeur ?',
  becomeTesterDesc:
    'Si vous souhaitez tester de nouvelles fonctionnalités en avance, envoyez-moi un email pour rejoindre le groupe de testeurs volontaires !',
  // Button to decline joining as a tester
  noThanks: 'Non merci !',

  // Warning message when push notification permissions are denied
  pushDeniedTitle: 'Les notifications push sont actuellement désactivées pour cette application.',
  pushDeniedDesc:
    'Sans elles, vous ne serez pas averti lorsque ce sera votre tour et vous risquez de concéder automatiquement des parties.',

  // Settings
  settings: 'Paramètres',
  // Button to set/save the player's username
  set: 'Définir',
  // To change if the app will make sounds or not
  sounds: 'Sons',
  // The player's name visible to others online
  username: 'Nom d’utilisateur',
  // The app's language
  language: 'Langue',
  // Lock screen awake
  lockScreen: 'Garder l’écran éveillé pendant les parties',
  // Player colour settings
  playerColours: 'Couleurs des joueurs',
  // emoji tone
  emojiTone: 'Ton des emojis',

  // Button to show more of the history moves
  showMore: 'Afficher plus',

  // User Key (for switching accounts)
  userKey: 'Clé de compte utilisateur',
  userKeyDesc:
    'Attention : n’utilisez cela que si vous savez ce que vous faites. Ne la partagez avec personne.',

  // Send email requesting deletion of user account
  requestDeleteAccount: 'Demander la suppression du compte',

  // Show last turn in the display
  lastTurnSetting: 'Visualiser le dernier tour de chaque joueur',

  // Turn Progress toggle
  turnProgressSetting: 'Afficher la "Progression du tour" pendant les parties',
  turnProgressSettingDesc:
    'La "Progression du tour" correspond au % des colonnes complétées. Par exemple, si vous parvenez à compléter les 3 colonnes d’un coup, cela afficherait 100 %. Une fois activée, elle sera visible en haut à droite de l’écran de jeu, dans le panneau Historique et l’écran de revanche.',

  // *** Keyboard Shortcuts ***

  // Turn on/off keyboard shortcuts for games
  keyShortcutsSetting: 'Raccourcis clavier',

  // Spacebar key
  keySpace: 'Espace',

  // Ctrl key
  keyCtrl: 'Ctrl',

  // Shift key
  keyShift: 'Maj',

  // "Next" action
  keyActionNextDesc: '"Suivant" (continuer, prochaine salle ou option de dés unique).',
  keyActionNextNote: 'Remarque : Lorsque le bouton "suivant" est présent, il aura une bordure.',

  // Dice option
  keyActionNumDesc: 'Choisissez l’option de dé avec ce numéro.',
  keyActionNumNote:
    'Remarque : S’il n’existe qu’une seule option pour 10-12, il suffit d’appuyer sur 1.',

  // Dice position
  keyActionCtrlNumDesc: 'Choisissez l’option de dé à cette position.',
  // {0} is Ctrl (or another key)
  keyActionCtrlNumNote: 'ex. {0}+1 est la première option',

  // Action to open the keyboard shortcuts instructions
  keyActionOpenHelpDesc: 'Ouvrir ces instructions.',

  includeLostGames: 'Inclure les parties perdues dans les prochaines parties',
  includeLostGamesDesc:
    'Les boutons "Aller à #" (qui vous amènent à votre prochain coup) incluront les parties que vous avez récemment perdues.',

  you: 'Vous',

  // Your Games
  yourGames: 'Vos parties',
  notInGames: 'Vous n’êtes actuellement dans aucune partie active.',

  // Errors
  waitingLimit: 'Vous avez atteint votre limite de nouvelles parties en attente de début.',
  basicLimit: 'L’édition {basicEdition} vous permet seulement de créer cinq parties à la fois.',
  serverTimeout: 'Délai de connexion du serveur dépassé. Réessayez.',
  samePlayersLimit:
    'Vous avez atteint la limite du nombre de parties actives avec exactement les mêmes joueurs.',

  // Bot check
  typeOk: 'Veuillez taper "ok" ci-dessous pour continuer...',

  // Error page
  somethingWrong: 'Un problème est survenu !',
  somethingWrongDesc:
    'Désolé ! Merci de m’envoyer un email avec les informations suivantes et une brève description de ce que vous faisiez et je réparerai cela au plus vite :',

  // Common app localisations:
  privacyPolicy: 'Politique de confidentialité',
  // Link to support the developer
  supportDeveloper: 'Acheter un café pour le développeur',

  // Button to say no
  no: 'Non',
  // Button to say yes
  yes: 'Oui',
  // Close/exit button
  close: 'Fermer',
  // Button to continue playing
  continue: 'Continuer',
  comingSoon: 'Bientôt disponible !',
  reset: 'Réinitialiser',
  ok: 'OK',

  // The app's colour theme (light/dark)
  appTheme: 'Thème de l’application',
  appThemeSystem: 'Par défaut système',
  appThemeLight: 'Mode clair',
  appThemeDark: 'Mode sombre',

  // *** User Account ***
  signedInAsX: 'Connecté en tant que {0}',
  signedInDesc: 'Vous pouvez vous connecter depuis d’autres appareils.',
  signOut: 'Déconnexion',
  notSignedIn: 'Non connecté.',
  signInToSave:
    'Connectez-vous pour sauvegarder votre compte joueur et vos paramètres. Vous pouvez vous connecter depuis d’autres appareils !',
  signInNew:
    'Nouveau sur l’application ? Inscrivez-vous pour sauvegarder votre compte joueur et vos paramètres. Vous pouvez vous connecter depuis d’autres appareils ! Si vous avez déjà un compte, connectez-vous ci-dessous.',
  signInRegister: 'Connexion / Inscription',

  // The 3 below are joined together to form one message with the middle as a button
  guestModeWarning1: 'Vous utilisez le mode invité.',
  guestModeWarning2Btn: 'Connectez-vous',
  guestModeWarning3: "pour protéger votre compte et jouer depuis d'autres appareils.",

  // Screenshot text
  // Each screenshot has text above and text below, which is why each of the below are in 2:
  screenshotPlayAgainst: `"Can't Stop" Jeu de Dés`,
  screenshotPlayAgainst2: 'Jouez contre des amis, en ligne & hors ligne',
  screenshotCreateOnline: 'Créez des parties en ligne',
  screenshotCreateOnline2: 'Inclut les variantes "Sauter" & "Bloquer" !',
  screenshotPlayFriends: 'Jouez avec des amis',
  screenshotPlayFriends2: "Jusqu'à 4 joueurs en ligne !",
  screenshotJoinPublicPrivate: 'Rejoignez des parties publiques ou privées',
  screenshotJoinPublicPrivate2: 'Affrontez aussi des inconnus !',
  screenshotOffline: "Jeu en mode Pass 'n' Play",
  screenshotOffline2: 'Jouez hors ligne avec des amis',
  screenshotInvite: 'Invitez des amis à jouer gratuitement',
  screenshotInvite2: "Avec l'édition de base ou l'édition web",
  screenshotLimitedFree: "Fonctionnalités limitées avec l'édition de base",
  screenshotLimitedFree2: 'Soutenu par la publicité & ne peut pas créer de parties en ligne.',
  screenshotSettings: 'Modifiez les paramètres',
  screenshotSettings2: 'Inclut les modes clair & sombre',

  // App listing
  appStoreNameFull: "Can't Stop: Jeu de Dés", // Maximum 50 characters
  appStoreSubtitleFull: 'Affrontez vos amis et lancez les dés !', // Maximum 80 characters
  appStoreDescriptionFull: `Jeu de dés populaire entièrement équipé basé sur le jeu de plateau "Can't Stop". Jouez en ligne ou hors ligne (Pass 'n' Play). 2 à 4 joueurs.

    Essayez différentes variantes ou restez fidèle à la version classique !
    
    Vos amis peuvent rejoindre votre partie même s'ils n'ont pas l'application. L'application est nécessaire pour créer de nouvelles parties et jouer hors ligne.`,

  appStoreNameBasic: "Can't Stop: Jeu de Dés (Basique)", // Maximum 50 characters
  appStoreSubtitleBasic: 'Affrontez vos amis et lancez les dés gratuitement !', // Maximum 80 characters
  appStoreDescriptionBasic: `Ceci est l'édition basique du jeu de dés populaire basé sur le jeu de plateau "Can't Stop". Jouez en ligne ou hors ligne (Pass 'n' Play). 2 à 4 joueurs.

    Essayez différentes variantes ou restez fidèle à la version classique !
    
    L'application complète est nécessaire pour créer de nouvelles parties en ligne.`,
};

export default fr;
