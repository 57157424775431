export const INTERSTITIAL_EVERY = 1; // every X clicks back to the menu
export const INTERSTITIAL_LIMIT = 1000;
export const AD_TIME_LIMIT = 5; // at least X minutes between ads
export const SIGN_IN_FEATURE_ON = false;

export interface SettingsType {
  connected: boolean;
  userId: number;
  userName: string;
  userAuth: string;
  email: string;
  isAdmin: boolean;
  online: boolean;
  mode: string | false;
  createdRooms: string[];
  activeRooms: string[];
  registered: boolean;
  sounds: boolean;
  showLastTurn: boolean;
  includeLost: boolean;
  lockScreen: boolean;
  firstTime: boolean;
  freeJoinPublic: boolean;
  shownRating: boolean;
  shownRatingDate: string | Date;
  joinedFrom: boolean | string;
  isLoading: boolean;
  askedTester: boolean;
  firstInstalled: string | boolean | number;
  push: string | false;
  yourTurnRooms: string[] | false;
  joinTab?: number;
  roomHistory?: string[];
  keyShortcuts?: boolean;
  playerColours?: { [i: number]: string } | false;
  showTurnProgress?: boolean;
}
export type JoinRoomType = (
  roomIdPre: false | string,
  reset?: () => void,
  joinedFrom?: string | boolean,
  viewOnly?: boolean,
  saveToHistory?: boolean,
) => void;
export type SetSettingsType = (f: SettingsType | ((a: SettingsType) => SettingsType)) => void;
export interface NotificationMessageType {
  roomId: string;
  title: string;
  body: string;
  event: string;
  openSnack: boolean;
  joinRoom: () => void;
}
export const defaultNotificationMessage: NotificationMessageType = {
  roomId: '',
  title: '',
  body: '',
  event: '',
  openSnack: false,
  joinRoom: () => false,
};
export type SetNotificationMessageType = (
  f: NotificationMessageType | ((m: NotificationMessageType) => NotificationMessageType),
) => void;

// %% FEATURE_MODE %%
export const FEATURE_MODE: 'PAID_APP' | 'FREE_APP' | 'WEB' | 'WEB' =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? 'PAID_APP' : 'WEB'; // PAID_APP, FREE_APP, WEB

// %% APP_VERSION %%
export const appVersion: string =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? 'DEV' : '3.6.18';

// %% LAUNCH_DATE %%
// parseInt('%% LAUNCH_DATE %%')
export const launchDate: number =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
    ? new Date().getTime() - 100
    : 1731627572981;

export const ENC_KEY = '29F8FZ$j$qzv33-J.Qw4%';

export const LS_KEYS = [
  'userId',
  'userName',
  'userNameSet',
  'userAuth',
  'email',
  'isAdmin',
  // 'activeRooms',
  'sounds',
  'lockScreen',
  'freeJoinPublic',
  'shownRatingDate',
  'askedTester',
  'firstInstalled',
  // 'newGameSettings',
  // 'newGameSettingsOnline',
  // 'newGameSettingsOffline',
  'themeMode',
  'language',
  'aiSpeed',
  'emojiTone',
  'endpoint',
  'version3',
  'version3.3',
];

// export const APP_NAME = `Can't Stop, Won't Stop`;
export const COLOURS: { [i: number]: string } = {
  0: '#0293ed', // '#28adfd', // blue
  1: '#e83c21', // '#ea4c32', // red
  2: '#38a013', // '#45c617', // green
  3: '#f9a406', // '#f9ac19', // orange
};
export const getColours = (
  settings: SettingsType,
  pos: number,
  players: number,
): typeof COLOURS => {
  // console.log('getColours pos', pos);
  if (pos < 0) return COLOURS; // current user not playing

  // blue, red, green, orange, blue, red, green orange

  // 0 = 0
  // 1 = 3
  // 2 = 2
  // 3 = 1
  const newPos = players - pos;

  const c = Object.values(COLOURS).slice(0, players);
  const o = [...c, ...c].slice(newPos, newPos + players);

  if (settings.playerColours) {
    const pc = settings.playerColours;
    const p = Object.keys(pc)
      .map((k) => parseInt(k, 10))
      .slice(0, players);
    p.forEach((k) => {
      // console.log('getColours', k, pc[k], pos, pos + k, (pos + k) % players);
      o[(pos + k) % players] = pc[k];
    });
  }

  // o[pos] = '#ed1c7a';
  const out = o.reduce((a, b, i) => ({ ...a, [i]: b }), {});

  // console.log('getColours pos', pos, newPos, c, o, out);
  return out;
  // return { ...COLOURS, [pos]: '#ed1c7a' };
};
// export const COLOURS_FADED = {
//   0: '#0293ed', // '#28adfd', // blue
//   1: '#ff664e', // '#ea4c32', // red
//   2: '#38a013', // '#45c617', // green
//   3: '#f9a406', // '#f9ac19', // orange
// };
// export const COLOURS_DARK: { [a: number]: string } = {
//   0: '#007bc7', // '#28adfd', // blue
//   1: '#c92d14', // '#ea4c32', // red
//   2: '#2b820c', // '#45c617', // green
//   3: '#d48b04', // '#f9ac19', // orange
// };
export const LANGUAGES: { [l: string]: string } = {
  de: 'Deutsch',
  en: 'English',
  es: 'Español',
  fr: 'Français',
  ja: '日本語',
  pt: 'Português',
  zh: '中文',
};
export const LANGUAGE_FLAGS: { [l: string]: string } = {
  en: 'GB',
  ja: 'JP',
  es: 'ES',
  zh: 'CN',
  de: 'DE',
  fr: 'FR',
  pt: 'PT',
};
export const DRAWER_WIDTH = 260;
export const REACTIONS: {
  [k: string]: { emoji: string; txt: string; tones?: string[]; hide?: boolean };
} = {
  smile: {
    emoji: '😊',
    txt: ':)',
  },
  yay: {
    emoji: '🥳',
    txt: 'Yay!',
  },
  phew: {
    emoji: '😅',
    txt: 'Phew!',
  },
  lol: {
    emoji: '😂',
    txt: 'LOL',
  },
  mindblown: {
    emoji: '🤯',
    txt: 'Mind blown!',
  },
  frown: {
    emoji: '🙁',
    txt: ':(',
  },
  cry: {
    emoji: '😢',
    txt: 'Sad',
  },
  wow: {
    emoji: '😮',
    txt: 'Wow!',
  },
  hmm: {
    emoji: '🤔',
    txt: 'Hmm',
  },
  angry: {
    emoji: '🤬',
    txt: 'Grrr',
  },
  shrug: {
    emoji: '🤷',
    tones: ['🤷🏻', '🤷🏼', '🤷🏽', '🤷🏾', '🤷🏿'],
    txt: '*Shrug*',
  },
  omg: {
    emoji: '😱',
    txt: 'OMG!',
  },
  crossed: {
    emoji: '🤞',
    tones: ['🤞🏻', '🤞🏼', '🤞🏽', '🤞🏾', '🤞🏿'],
    txt: 'Please!',
  },
  oops: {
    emoji: '🤦',
    tones: ['🤦🏻', '🤦🏼', '🤦🏽', '🤦🏾', '🤦🏿'],
    txt: 'Oh dear',
  },
  ok: {
    emoji: '👌',
    tones: ['👌🏻', '👌🏼', '👌🏽', '👌🏾', '👌🏿'],
    txt: 'OK!',
  },
  wave: {
    emoji: '👋',
    tones: ['👋🏻', '👋🏼', '👋🏽', '👋🏾', '👋🏿'],
    txt: 'Wave!',
  },
  clap: {
    emoji: '👏',
    tones: ['👏🏻', '👏🏼', '👏🏽', '👏🏾', '👏🏿'],
    txt: 'Nice!',
  },
  thumb: {
    emoji: '👍',
    tones: ['👍🏻', '👍🏼', '👍🏽', '👍🏾', '👍🏿'],
    txt: 'Thumb',
  },
  thumbdown: {
    emoji: '👎',
    tones: ['👎🏻', '👎🏼', '👎🏽', '👎🏾', '👎🏿'],
    txt: 'Thumb down',
  },

  zzz: {
    emoji: '😴',
    txt: 'Zzz',
  },
  giggle: {
    emoji: '🤭',
    txt: 'Hehe',
    // hide: true,
  },
  hug: {
    emoji: '🤗',
    txt: '*Hugs*',
    // hide: true,
  },
  flushed: {
    emoji: '😳',
    txt: 'Embarrassed',
    // hide: true,
  },
  vomit: {
    emoji: '🤢',
    txt: 'Vomit',
    // hide: true,
  },
  roll: {
    emoji: '🙄',
    txt: '*Roll eyes*',
    // hide: true,
  },
  confused: {
    emoji: '😕',
    txt: 'Confused',
    hide: true,
  },
  sob: {
    emoji: '😭',
    txt: 'Sob',
    hide: true,
  },
  xeyes: {
    emoji: '😵',
    txt: 'Xo',
    hide: true,
  },
  grimace: {
    emoji: '😬',
    txt: 'Grimace',
    hide: true,
  },
  halo: {
    emoji: '😇',
    txt: 'Halo',
    hide: true,
  },
  shake: {
    emoji: '🤝',
    txt: '*Hand shake*',
    // hide: true,
  },
  bow: {
    emoji: '🙇',
    tones: ['🙇🏻', '🙇🏼', '🙇🏽', '🙇🏾', '🙇🏿'],
    txt: '*Bow*',
    hide: true,
  },
  no: {
    emoji: '🙅',
    tones: ['🙅🏻', '🙅🏼', '🙅🏽', '🙅🏾', '🙅🏿'],
    txt: 'No',
    hide: true,
  },
  strong: {
    emoji: '💪',
    tones: ['💪🏻', '💪🏼', '💪🏽', '💪🏾', '💪🏿'],
    txt: '*Flex*',
    // hide: true,
  },

  pray: {
    emoji: '🙏',
    tones: ['🙏🏻', '🙏🏼', '🙏🏽', '🙏🏾', '🙏🏿'],
    txt: 'Pray',
    // hide: true,
  },
  handsup: {
    emoji: '🙌',
    tones: ['🙌🏻', '🙌🏼', '🙌🏽', '🙌🏾', '🙌🏿'],
    txt: '*Hands raised*',
    // hide: true,
  },
  eyes: {
    emoji: '👀',
    txt: 'Eyes',
    // hide: true,
  },
  poop: {
    emoji: '💩',
    txt: 'Poop',
    // hide: true,
  },
  fire: {
    emoji: '🔥',
    txt: 'Fire',
    hide: true,
  },
  skull: {
    emoji: '☠️',
    txt: 'Skull',
    hide: true,
  },
  broken: {
    emoji: '💔',
    txt: 'Broken Heart',
    hide: true,
  },
  almost: {
    emoji: '🤏',
    tones: ['🤏🏻', '🤏🏼', '🤏🏽', '🤏🏾', '🤏🏿'],
    txt: 'Almost',
    hide: true,
  },
  whiteflag: {
    emoji: '🏳',
    txt: 'White Flag',
    hide: true,
  },
};
// export const STRINGS = {
//   public_full: 'Public Game',
//   private_full: 'Private Game',
//   public: 'Public',
//   private: 'Private',
// };
export const BASE_UNIT_HEIGHT = 30;
export const COL_WIDTH = 24;

export const FADED = 0.4;

// export default '';
