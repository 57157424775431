// Every string is in the form:
// key: 'String text',
// The key is in light blue and the string text is in peach.
// Only ever translate the string text in a PEACH colour.
// Also don't edit the {variables} in curly brackets (see below for more info)

// Most strings have green comments on the line above to hopefully help explain and give context.

// *** {0}, {1}, etc ***
// These are variables inserted into the text. The variables will be explained in the line(s) above.

// *** {namedStrings} ***
// There are using other strings from the rest of the file.
// e.g. 'The app is {appName}!' would result in "The app is Can't Stop, Won't Stop"
// If the use of that string doesn't actually work in the local language you can
// just remove the "{namedString}" and write out the full text.

// *** _plural ***
// In English and many other languages there are singular and plural forms of words, such as "1 cat" vs "2 cats".
// When a variable (like {0}) is a number, sometimes there needs to be two strings, such as:
// xCats: '{0} cat', (when {0} is 1)
// xCats_plural: '{0} cats', (when {0} is anything other than 1)
// If the new language doesn't have plurals, you can just delete the lines where the key ends with "_plural".

const es = {
  lang: 'es', // Don't edit this
  appStoreLang: 'es', // Don't edit this
  playStoreLang: 'es', // Don't edit this
  dateAt: ' a las ', // Don't edit this
  // App
  appName: "Can't Stop, Won't Stop",

  // Version change log title. e.g. "What's New In 3.6?"
  whatsNewInX: `Novedades en {0}`,

  // Alert in the menu if there's no user data, asking if their account is missing
  recoveryFailedTitle: '¿Faltan tus juegos?',
  recoveryFailedDesc:
    'Si tus juegos han desaparecido después de instalar una actualización, ¡no te preocupes! Por favor envíame un correo con tu nombre de usuario anterior y te pondré en marcha de nuevo.',

  // General
  // Question confirming the user wants to do the action
  areYouSure: '¿Estás seguro?',
  notConnected: 'Actualmente no estás conectado al servidor.',
  // Email button
  emailMe: 'Envíame un correo',
  // Email Address text field
  emailAddress: 'Dirección de correo electrónico',
  // Helper text for privacy of email
  emailHelper: 'Tu correo electrónico solo se utilizará para recuperar tu cuenta.',
  // When a user is also an admin
  admin: 'Administrador',
  // The full paid version of the app
  fullEdition: 'Premium',
  // The limited free version of the app
  basicEdition: 'Edición Básica',
  // The website version of the app
  webEdition: 'Edición Web',
  // An unknown type of user
  unknownUser: 'Usuario Desconocido',
  // Not connected to the internet
  offline: 'Sin conexión',
  offlineGame: 'Juego sin conexión',
  offlineGameDesc:
    'Un juego sin conexión de "Pasa y Juega" para jugar con amigos en la misma habitación o simplemente practicar contra la IA.',
  // Button for downloading on the Apple App Store
  downloadAppStore: 'Descargar en el App Store',
  // Button for downloading on the Google Play Store
  downloadPlayStore: 'Obtener en Google Play',
  // Button to expand the form and show the more advanced options
  showAdvanced: 'Mostrar avanzado',
  // Displayed when the app is connecting to the game server
  connecting: 'Conectando',
  // Displayed when the app is loading a game or page
  loading: 'Cargando',
  // A star is shown next to players with the best scores
  topPlayer: 'Mejor Jugador',
  // An icon is shown next to new players
  newPlayer: 'Nuevo Jugador',

  // AI Players (for offline games)...
  // The speed of the AI player
  aiSpeed: 'Velocidad del Jugador IA',
  // The slowest AI player
  slowSpeed: 'Lento',
  // The normal speed AI player
  normalSpeed: 'Normal',
  // The fast speed AI player
  fastSpeed: 'Rápido',
  // The fastest AI player, where moves are instant
  instantSpeed: 'Instantáneo',

  // Human player
  human: 'Humano',
  // AI player risk level 1: Safe
  aiSafe: 'Seguro',
  // AI player risk level 2: Normal
  aiNormal: 'Normal',
  // AI player risk level 3: Risky
  aiRisky: 'Arriesgado',
  // AI player risk level 4: Crazy
  aiCrazy: 'Loco',
  // AI player risk level 5: Extreme
  aiExtreme: 'Extremo',

  // AI player
  ai: 'IA',
  humanFull: '{human}',
  aiSafeFull: '{ai}: {aiSafe}',
  aiNormalFull: '{ai}: {aiNormal}',
  aiRiskyFull: '{ai}: {aiRisky}',
  aiCrazyFull: '{ai}: {aiCrazy}',
  aiExtremeFull: '{ai}: {aiExtreme}',
  // Note for when a feature is only available in the full edition:
  onlyInFull: 'Disponible solo para usuarios de {fullEdition}.',
  // The same as above but in brackets and shorter
  onlyInFullBrackets: '({fullEdition} solamente)',

  // MenuPage
  // {0} is the name of the app in bold
  welcomeToApp: '¡Bienvenido a {0}!',
  // Welcome intro
  welcomeToAppDesc:
    'Usa el menú de configuración para cambiar tu nombre de usuario y el menú de ayuda si no estás seguro de cómo jugar.',
  freeJoinPublic:
    "ACTUALIZACIÓN: ¡Los jugadores con la {basicEdition} ahora pueden unirse a juegos públicos! Toca '{joinOnlineGame}' abajo...",

  // Welcome message options
  // {0} is the username
  hiUser1: '¡Oh hola, {0}!',
  hiUser2: '¡Bienvenido, {0}!',
  hiUser3: '¡Hola, {0}!',
  hiUser4: '¡Hey, {0}!',
  hiUser5: '¡Hola, {0}!',

  // Button to create a new online game
  newOnlineGame: 'Crear Juego en Línea',
  // Button to join an existing online game
  joinOnlineGame: 'Unirse a Juego en Línea',
  // Button to start and play a new offline game
  newOfflineGame: 'Nuevo Juego sin Conexión',
  // Confirming with the user that they want to start a new offline game when they already have an existing game
  newOfflineGameConfirm: '¿Nuevo Juego sin Conexión?',
  newOfflineGameConfirmDesc: 'Esto terminará el juego sin conexión actual. {areYouSure}',
  // Button to continue playing an existing offline game
  continueOfflineGame: 'Continuar Juego sin Conexión',

  // Button to take the user to their active games
  // {0} is the number of active games the user has
  yourXGames: 'Tu {0} Juego',
  yourXGames_plural: 'Tus {0} Juegos',

  // Button to take the user to one specific game
  // {0} is the game ID
  goToRoom: 'Ir a #{0}',

  // Button to join a specific game (usually by invitation)
  // {0} is the game ID
  joinX: 'Unirse a #{0}',

  // Button to go to the game rules
  howToPlay: 'Cómo jugar',

  // Rules heading
  rules: 'Reglas',
  // Rules intro lines
  rulesIntro1:
    'En cada turno, el jugador lanza los cuatro dados, luego los divide en dos pares, sumando cada par. Por ejemplo, un jugador que lanza un 1, 2, 3 y 6 podría agruparlos como 5 y 7, 4 y 8, o 3 y 9.',
  rulesIntro2:
    'Elige una combinación para mover tus fichas en las columnas correspondientes. Solo tienes un número limitado de fichas por turno.',
  rulesIntro3:
    'Si no hay combinaciones posibles con los dados, tu turno termina y las fichas se pierden. Si decides detenerte, tus fichas serán reemplazadas con tu color.',
  rulesIntro4:
    'Las columnas 2 y 12 son cortas, pero es raro obtener esos valores, mientras que la columna 7 es la más larga pero la más común.',
  rulesIntro5:
    'Cuando un jugador alcanza la parte superior de una columna y se detiene, reclama la columna y se añade a su puntuación. Esa columna ya no se puede jugar durante el resto del juego. Cuando un jugador alcanza la puntuación objetivo, el juego termina.',

  // Online games with other people
  onlineGames: 'Juegos en Línea',
  onlineGamesDesc:
    'Puedes jugar en línea contra otros jugadores creando tu propio juego público o privado, o uniéndote a un juego existente.',

  // Offline games with friends in the same room or against AI
  offlineGames: 'Juegos sin Conexión ("Pasar y Jugar")',
  offlineGamesDesc:
    'Puedes jugar un juego sin conexión con un dispositivo y pasar el dispositivo entre los jugadores.',

  // Section about the original board game
  originalRules: 'Juego Original',
  videoIntro:
    '"Can\'t Stop, Won\'t Stop" está inspirado en el juego de mesa original "Can\'t Stop", pero no está afiliado a él de ninguna manera. Aquí tienes un par de videos de YouTube explicando el juego de mesa original, en caso de que nunca lo hayas jugado:',
  // {0} is the number of the video (e.g. 1 => "Video 1")
  videoX: 'Video {0}',

  // Privacy Policy
  privacyPolicyDesc:
    'No se recopilan tus datos personales. Las configuraciones de la app y los juegos que juegas se envían a un servidor, pero los datos solo se guardan temporalmente y nunca se comparten con terceros. Añadir tu dirección de correo electrónico es opcional y solo se usa para recuperar tu cuenta. Envía un correo a chris@mento.co.uk para solicitar que tus datos sean eliminados.',

  probabilityCalculator: 'Calculadora de Probabilidades',
  probabilityCalculatorDesc:
    'He creado una pequeña herramienta para ayudarte a entender las probabilidades de obtener una o más columnas. ¡Puede que te ayude!',

  // Game settings
  // {0} is the number of columns
  xColumns: '{0} Columna',
  xColumns_plural: '{0} Columnas',
  xColumnsAltDesc: 'Número de columnas requeridas para ganar.',
  // {0} is the number of columns
  xColumnsDesc: 'El primer jugador en completar {0} columna ganará el juego.',
  xColumnsDesc_plural: 'El primer jugador en completar {0} columnas ganará el juego.',

  // {0} is the number of markers
  xMarkers: '{0} Ficha',
  xMarkers_plural: '{0} Fichas',
  xMarkersAltDesc: 'Número máximo de columnas por turno.',
  // {0} is the number of columns
  xMarkersDesc: 'Puedes ocupar un máximo de {0} columna por turno.',
  xMarkersDesc_plural: 'Puedes ocupar un máximo de {0} columnas por turno.',

  // Optional game mode 1
  forceMax: 'Forzar fichas máximas',
  forceMaxDesc:
    'El jugador debe elegir una combinación de dados que use el mayor número de fichas nuevas.',

  // Optional game mode 2
  preventSkip: 'Evitar turnos perdidos',
  preventSkipDesc: 'Vuelve a lanzar para que un jugador nunca pierda todo su turno.',

  // Optional game mode 3
  preDetermine: 'Pre-determinar el siguiente lanzamiento de dados',
  preDetermineDesc:
    'Los próximos números de los dados se lanzan aleatoriamente antes de que toques {continue} o {stop}.',

  // Optional game mode 4
  doubleHeight: 'Doble Altura',
  doubleHeightDesc:
    'Todas las columnas tienen el doble de altura para que los juegos sean más largos.',

  // Public games can be seen and joined by anyone
  public: 'Público',
  public_full: 'Juego Público',
  publicDesc: 'Los juegos públicos pueden ser vistos y unirse cualquier persona.',

  // Private games can only be joined using the room code
  private: 'Privado',
  private_full: 'Juego Privado',
  privateDesc: 'Los juegos privados solo pueden ser unidos usando el código de la sala.',

  // Heading for the player types (which version of the app each player is using)
  userTypes: 'Tipos de Jugadores',

  // Game Info
  // {0} is the game ID
  currentGameX: 'Juego Actual #{0}',
  // {0} is a relative time in the past, for example "5 minutes ago"
  lastActivityX: 'Última actividad hace {0}',

  // For when a time is too recent to show the exact time
  justNow: 'ahora mismo',

  // Alert message shown when the user presses the button to copy something to the clipboard
  gameIdCopied: '{gameId} copiado al portapapeles!',
  websiteCopied: '¡Sitio web copiado al portapapeles!',

  // Waiting
  // {0} is the number of players joined so far
  // {1} is the total number of players that are needed for the game to start
  xOfYPlayersJoined: '{0} de {1} jugadores se han unido:',

  // Winning turn
  // {0} is the column number(s)
  // {1} is the Turn Progress percentage
  winningMoveSummaryShort: '{0} · {1}%',
  winningMoveSummaryLong: `Terminado completando la columna {0} con un 'Progreso del Turno' del {1}%`,

  // Full turn progress for the tooltip
  // {0} is the Turn Progress percentage
  turnProgressFull: `El 'Progreso del Turno' actual es {0}%`,
  // If it was the previous player's turn:
  turnProgressLast: `El 'Progreso del Turno' anterior fue {0}%`,

  // Cancel button
  cancel: 'Cancelar',

  // Button to fully cancel/end the game
  cancelGame: 'Cancelar Juego',
  // Confirmation about cancelling the game before it starts
  cancelGameDesc: 'Esto cancelará el juego para todos. ¿Estás seguro?',

  // Button to decline a rematch
  declineRematch: 'Rechazar Desempate',
  // Button to view the previous game from a rematch
  viewPrevious: 'Ver Juego Anterior',

  // Share App
  shareMessage: `🎲🎲 ¡Únete a mí y juega Can't Stop, el adictivo juego de dados! 🎲🎲`,

  // QR linked devices
  linkToWeb: 'Vincular a Web',
  linkToWebDesc: 'Ver y jugar tus juegos en la {webEdition}.',

  // Button to leave the game when waiting for other players to join
  leaveGame: 'Abandonar Juego',

  // Button to join a game
  joinGame: 'Unirse al Juego',
  // Button to join a rematch game
  joinRematch: 'Unirse a Desempate',

  // {0} is the website URL
  inviteAtX: 'Invita a tus amigos a unirse gratis en {0}',

  gameId: 'ID del Juego',
  gameIdHash: '{gameId} #',

  // Join button
  join: 'Unirse',
  enterGameId: 'Ingresa el {gameId} arriba para unirte al juego privado de un amigo.',
  // {0} is the name of the app
  listGamesFullApp: 'Si deseas ver una lista de juegos públicos, necesitarás la app {0}:',
  noPublicGames: 'Actualmente no hay juegos públicos para unirse.',
  newGameFullApp:
    '¿Por qué no crear un nuevo juego e invitar a tus amigos a unirse gratis (con la app {basicEdition} o la {webEdition})?',
  publicGames: 'Juegos Públicos',
  waiting: 'Esperando',
  publicGamesWaiting: '{publicGames}: {waiting}',
  inProgress: 'En Progreso',
  publicGamesInProgress: '{publicGames}: {inProgress}',
  recentlyFinished: 'Recientemente Terminado',
  publicGamesRecentlyFinished: '{publicGames}: {recentlyFinished}',

  // New Game
  createOnlineGame: 'Crear Juego en Línea',
  createOnlineGameDesc:
    '¡Crea un juego en línea y luego invita a tus amigos o espera a que se unan otros jugadores!',
  createOfflineGame: 'Crear Juego Sin Conexión',
  createOfflineGameDesc:
    'Crea un juego "Pásalo y Juega" sin conexión para jugar con amigos en la misma habitación o simplemente para practicar.',
  // {0} is the name of the app
  noNewGameWeb:
    'La Edición Web de {0} solo permite unirse a juegos por su ID. Si deseas crear nuevos juegos, necesitarás la app:',
  // {0} is the name of the app
  noNewGameBasic:
    'La Edición Básica de {0} solo permite unirse a juegos y crear juegos sin conexión. Si deseas crear un nuevo juego en línea, necesitarás la app completa sin anuncios:',
  basicLimitMessage:
    'La Edición Básica de {0} solo permite crear cinco juegos a la vez con configuraciones limitadas. Si deseas cambiar las opciones o crear más juegos, necesitarás la app completa sin anuncios:',
  // {0} is the number of players
  xPlayers: '{0} Jugadores',
  xPlayersDesc: 'Número de jugadores en el juego.',

  // Conceding
  concede: 'Conceder',
  conceded: 'Concedido',
  xConceded: '{0} concedido.',
  youConceded: 'Has concedido.',
  xGamesConceded: '{0} juego concedido ({1}%)',
  xGamesConceded_plural: '{0} juegos concedidos ({1}%)',

  // Games played
  xGamesPlayed: '{0} juego completado',
  xGamesPlayed_plural: '{0} juegos completados',
  xGamesPlaying: '{0} juego activo',
  xGamesPlaying_plural: '{0} juegos activos',
  // xStopRate: 'Reciente: {1}% / General: {0}%',
  xStopRate: 'Tasa de Parada de {0}',
  xStopRateDesc: '(turnos que terminan con una parada)',
  vsFormDesc: 'Tus resultados recientes contra {0}',

  recent: 'Reciente',
  allTime: 'Todo el Tiempo',

  concedeCheck:
    '¿Estás seguro de que deseas conceder este juego? No se puede deshacer y puede afectar tu puntuación/estado.',

  // Rematch
  rematch: 'Desempate',
  invited: 'Invitado',
  rematchProposed: 'Desempate Propuesto',

  // Replay from here button
  replay: 'Repetir Desde Aquí',
  // Button to stop the replay
  stopReplay: 'Detener Repetición',

  // Recent form: Win
  formWin: 'Victoria',
  // Recent form: Loss
  formLoss: 'Derrota',
  // Recent form: Conceded
  formConceded: 'Concedido',

  recentForm: 'Forma Reciente de {0}',

  // Game Modes

  // The classic normal rules of the game
  classic: 'Clásico',
  classicFull: 'Modo Clásico',
  classicDesc: 'Las reglas clásicas.',

  // The "blocking" variant of the game
  block: 'Bloqueo',
  blockFull: 'Variante "Bloqueo"',
  blockDesc: 'Detenerse en cualquier posición igual a otra jugador está bloqueado.',

  // The "Jumping" variant of the game
  jump: 'Salto',
  jumpFull: 'Variante "Salto"',
  jumpDesc:
    'Saltas automáticamente sobre otros jugadores para que nunca estén en la misma posición.',

  // Game
  youWin: '¡Tú Ganas!',
  youWon: '¡Has Ganado!',
  // {0} is the winning player name
  xWins: '¡{0} Gana!',
  xWon: '{0} Ganó',
  youLost: 'Has Perdido',
  xWinRate: 'Tasa de Victoria del {0}%',
  neither: 'Ninguno',

  // Alert when there are no more possible moves
  noPossibleMoves: 'No hay movimientos posibles',

  // Button to stop and pass to the next player
  stop: 'Detener',

  gameOver: '¡Juego Terminado!',
  // Button to start a new game
  newGame: 'Nuevo Juego',
  // Notification and history
  gameStarted: '¡Juego iniciado!',
  // Button to end the game early
  endGame: 'Terminar Juego',
  startGame: 'Iniciar Juego',
  nextGame: 'Siguiente Juego',

  // Confirmation about ending the game early
  endGameEarly: '¿Terminar Juego Antes?',
  endGameEarlyDesc: 'Esto terminará el juego actual para todos. ¿Estás seguro?',

  yourTurn: 'Tu Turno',
  // {0} is the player's name
  xTurn: 'Turno de {0}',
  // The status of a finished game
  finished: 'Terminado',
  // The status of a game in progress
  playing: 'Jugando',
  // {0} is the number of players
  waitingForX: 'Esperando a {0}',
  // {0} is the number of players
  waitingForXPlayers: 'Esperando a {0} jugador',
  waitingForXPlayers_plural: 'Esperando a {0} jugadores',
  // {0} is a relative time in the past, for example "2 days ago"
  createdX: 'Creado hace {0}',

  // New version
  newVersion: 'Nueva Versión Disponible',
  newVersionDesc: 'La versión {0} está lista para descargar ahora.',

  // Beta Tester
  becomeTester: '¿Convertirse en tester?',
  becomeTesterDesc:
    'Si deseas probar nuevas funciones antes, envíame un correo para unirte al grupo de testers voluntarios.',
  // Button to decline joining as a tester
  noThanks: '¡No, Gracias!',

  // Warning message when push notification permissions are denied
  pushDeniedTitle: 'Las Notificaciones Push están actualmente desactivadas para esta app.',
  pushDeniedDesc:
    'Sin ellas, no recibirás alertas cuando sea tu turno y corres el riesgo de conceder juegos automáticamente.',

  // Settings
  settings: 'Ajustes',
  // Button to set/save the player's username
  set: 'Establecer',
  // To change if the app will make sounds or not
  sounds: 'Sonidos',
  // The player's name visible to others online
  username: 'Nombre de Usuario',
  // The app's language
  language: 'Idioma',
  // Lock screen awake
  lockScreen: 'Mantener la pantalla despierta durante los juegos',
  // Player colour settings
  playerColours: 'Colores de Jugador',
  // emoji tone
  emojiTone: 'Tono de Emoji',

  // Button to show more of the history moves
  showMore: 'Mostrar Más',

  // User Key (for switching accounts)
  userKey: 'Clave de Cuenta de Usuario',
  userKeyDesc:
    'Advertencia: usa esto solo si sabes lo que estás haciendo. No lo compartas con nadie.',

  // Send email requesting deletion of user account
  requestDeleteAccount: 'Solicitar Eliminación de Cuenta',

  // Show last turn in the display
  lastTurnSetting: 'Visualizar el último turno de cada jugador',

  // Turn Progress toggle
  turnProgressSetting: 'Mostrar el "Progreso del Turno" durante los juegos',
  turnProgressSettingDesc:
    '"Progreso del Turno" es el % de las columnas actuales completadas. Por ejemplo, si logras completar las 3 columnas desde cero en un turno, mostrará 100%. Una vez activado, se mostrará en la parte superior derecha de la pantalla del juego, en el panel de Historial y en la pantalla de desempate.',

  // *** Keyboard Shortcuts ***

  // Turn on/off keyboard shortcuts for games
  keyShortcutsSetting: 'Atajos de Teclado',

  // Spacebar key
  keySpace: 'Espacio',

  // Ctrl key
  keyCtrl: 'Ctrl',

  // Shift key
  keyShift: 'Mayús',

  // "Next" action
  keyActionNextDesc: '"Siguiente" (continuar, siguiente sala o opción de dado único).',
  keyActionNextNote: 'Nota: Cuando hay un botón de "siguiente", tendrá un borde.',

  // Dice option
  keyActionNumDesc: 'Elegir opción de dado con ese número.',
  keyActionNumNote: 'Nota: Si solo hay una opción para 10-12, solo necesitas presionar 1.',

  // Dice position
  keyActionCtrlNumDesc: 'Elegir opción de dado en esa posición.',
  // {0} is Ctrl (or another key)
  keyActionCtrlNumNote: 'p.ej. {0}+1 es la primera opción',

  // Action to open the keyboard shortcuts instructions
  keyActionOpenHelpDesc: 'Abrir estas instrucciones.',

  includeLostGames: 'Incluir juegos perdidos en Próximos Juegos',
  includeLostGamesDesc:
    'Los botones "Ir a #" (que te llevan a tu siguiente movimiento) incluirán juegos que has perdido recientemente.',

  you: 'Tú',
  // Your Games
  yourGames: 'Tus Juegos',
  notInGames: 'No estás actualmente en ningún juego activo.',

  // Errors
  waitingLimit: 'Has alcanzado tu límite de nuevos juegos que aún están esperando comenzar.',
  basicLimit: 'La {basicEdition} solo permite crear cinco juegos a la vez.',
  serverTimeout: 'Tiempo de espera del servidor. Inténtalo de nuevo.',
  samePlayersLimit: 'Has alcanzado el límite de juegos activos con los mismos jugadores.',

  // Bot check
  typeOk: 'Por favor, escribe "ok" a continuación para continuar...',

  // Error page
  somethingWrong: '¡Algo ha ido mal!',
  somethingWrongDesc:
    '¡Lo siento! Por favor, envíame un correo electrónico con la siguiente información y una breve descripción de lo que estabas haciendo y lo solucionaré lo antes posible:',

  // Common app localisations:
  privacyPolicy: 'Política de Privacidad',
  // Link to support the developer
  supportDeveloper: 'Invitar al desarrollador a un café',

  // Button to say no
  no: 'No',
  // Button to say yes
  yes: 'Sí',
  // Close/exit button
  close: 'Cerrar',
  // Button to continue playing
  continue: 'Continuar',
  comingSoon: '¡Próximamente!',
  reset: 'Restablecer',
  ok: 'OK',

  // The app's colour theme (light/dark)
  appTheme: 'Tema de la App',
  appThemeSystem: 'Por defecto del sistema',
  appThemeLight: 'Modo Claro',
  appThemeDark: 'Modo Oscuro',

  // *** User Account ***
  signedInAsX: 'Conectado como {0}',
  signedInDesc: 'Puedes iniciar sesión desde otros dispositivos.',
  signOut: 'Cerrar sesión',
  notSignedIn: 'No conectado.',
  signInToSave:
    'Inicia sesión para guardar tu cuenta de jugador y configuraciones. ¡Puedes iniciar sesión desde otros dispositivos!',
  signInNew:
    '¿Nuevo en la app? Regístrate para guardar tu cuenta de jugador y configuraciones. ¡Puedes iniciar sesión desde otros dispositivos! Si ya tienes una cuenta, inicia sesión a continuación.',
  signInRegister: 'Iniciar sesión / Registrarse',

  // The 3 below are joined together to form one message with the middle as a button
  guestModeWarning1: 'Estás usando el modo Invitado.',
  guestModeWarning2Btn: 'Iniciar sesión',
  guestModeWarning3: 'para proteger tu cuenta y jugar desde otros dispositivos.',

  // Screenshot text
  // Each screenshot has text above and text below, which is why each of the below are in 2:
  screenshotPlayAgainst: `"Can't Stop" Juego de Dados`,
  screenshotPlayAgainst2: 'Juega Contra Amigos, En Línea y Fuera de Línea',
  screenshotCreateOnline: 'Crea Juegos En Línea',
  screenshotCreateOnline2: '¡Incluyendo variantes "Saltar" y "Bloquear"!',
  screenshotPlayFriends: 'Juega Con Amigos',
  screenshotPlayFriends2: '¡Hasta 4 Jugadores En Línea!',
  screenshotJoinPublicPrivate: 'Únete a Juegos Públicos o Privados',
  screenshotJoinPublicPrivate2: '¡Juega también contra extraños!',
  screenshotOffline: "Pasa 'n' Juega",
  screenshotOffline2: 'Juega Juegos Fuera de Línea Con Amigos',
  screenshotInvite: 'Invita a Amigos a Jugar Gratis',
  screenshotInvite2: 'Usando la app de Edición Básica o Edición Web',
  screenshotLimitedFree: 'Funciones Limitadas con Edición Básica',
  screenshotLimitedFree2: 'Con anuncios y no se pueden crear juegos en línea.',
  screenshotSettings: 'Cambiar Configuraciones',
  screenshotSettings2: 'Incluyendo modos claro y oscuro',

  // App listing
  appStoreNameFull: "Can't Stop: Juego de Dados", // Maximum 50 characters
  appStoreSubtitleFull: '¡Juega con tus amigos y lanza los dados!', // Maximum 80 characters
  appStoreDescriptionFull: `Juego de dados popular basado en el juego de mesa "Can't Stop". Juega en línea o fuera de línea (Pasa y Juega). 2-4 jugadores.

    ¡Prueba diferentes variaciones o apegarte a la versión clásica!
    
    Tus amigos pueden unirse a tu juego incluso si no tienen la app. La app es necesaria para crear nuevos juegos y jugar juegos fuera de línea.`,

  appStoreNameBasic: "Can't Stop: Juego de Dados (Básico)", // Maximum 50 characters
  appStoreSubtitleBasic: '¡Juega con tus amigos y lanza los dados gratis!', // Maximum 80 characters
  appStoreDescriptionBasic: `Esta es la Edición Básica del popular juego de dados basado en el juego de mesa "Can't Stop". Juega en línea o fuera de línea (Pasa y Juega). 2-4 jugadores.

    ¡Prueba diferentes variaciones o apegarte a la versión clásica!
    
    La app completa es necesaria para crear nuevos juegos en línea.`,
};

export default es;
