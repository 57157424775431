// Every string is in the form:
// key: 'String text',
// The key is in light blue and the string text is in peach.
// Only ever translate the string text in a PEACH colour.
// Also don't edit the {variables} in curly brackets (see below for more info)

// Most strings have green comments on the line above to hopefully help explain and give context.

// *** {0}, {1}, etc ***
// These are variables inserted into the text. The variables will be explained in the line(s) above.

// *** {namedStrings} ***
// There are using other strings from the rest of the file.
// e.g. 'The app is {appName}!' would result in "The app is Can't Stop, Won't Stop"
// If the use of that string doesn't actually work in the local language you can
// just remove the "{namedString}" and write out the full text.

// *** _plural ***
// In English and many other languages there are singular and plural forms of words, such as "1 cat" vs "2 cats".
// When a variable (like {0}) is a number, sometimes there needs to be two strings, such as:
// xCats: '{0} cat', (when {0} is 1)
// xCats_plural: '{0} cats', (when {0} is anything other than 1)
// If the new language doesn't have plurals, you can just delete the lines where the key ends with "_plural".

const pt = {
  lang: 'pt',
  appStoreLang: 'pt',
  playStoreLang: 'pt',
  dateAt: ' às ',
  // App Name
  appName: "Can't Stop, Won't Stop",

  // Version change log title
  whatsNewInX: `Novidades em {0}`,

  // Alert in the menu if there's no user data, asking if their account is missing
  recoveryFailedTitle: 'Seus jogos sumiram?',
  recoveryFailedDesc:
    'Se seus jogos desapareceram após a instalação de uma atualização, não se preocupe! Envie-me um e-mail com seu nome de usuário anterior e eu o colocarei de volta em funcionamento.',

  // General
  // Question confirming the user wants to do the action
  areYouSure: 'Você tem certeza?',
  notConnected: 'Você não está conectado ao servidor no momento.',
  // Email button
  emailMe: 'Envie-me um e-mail',
  // Email Address text field
  emailAddress: 'Endereço de E-mail',
  // Helper text for privacy of email
  emailHelper: 'Seu e-mail será usado apenas para recuperar sua conta.',
  // When a user is also an admin
  admin: 'Admin',
  // The full paid version of the app
  fullEdition: 'Premium',
  // The limited free version of the app
  basicEdition: 'Edição Básica',
  // The website version of the app
  webEdition: 'Edição Web',
  // An unknown type of user
  unknownUser: 'Usuário Desconhecido',
  // Not connected to the internet
  offline: 'Offline',
  offlineGame: 'Jogo Offline',
  offlineGameDesc:
    'Um jogo offline "Passar e Jogar" para jogar com amigos na mesma sala ou apenas para praticar contra a IA.',
  // Button for downloading on the Apple App Store
  downloadAppStore: 'Baixar na App Store',
  // Button for downloading on the Google Play Store
  downloadPlayStore: 'Obter no Google Play',
  // Button to expand the form and show the more advanced options
  showAdvanced: 'Mostrar Avançado',
  // Displayed when the app is connecting to the game server
  connecting: 'Conectando',
  // Displayed when the app is loading a game or page
  loading: 'Carregando',
  // A star is shown next to players with the best scores
  topPlayer: 'Melhor Jogador',
  // An icon is shown next to new players
  newPlayer: 'Novo Jogador',

  // AI Players (for offline games)...
  // The speed of the AI player
  aiSpeed: 'Velocidade do Jogador IA',
  // The slowest AI player
  slowSpeed: 'Lento',
  // The normal speed AI player
  normalSpeed: 'Normal',
  // The fast speed AI player
  fastSpeed: 'Rápido',
  // The fastest AI player, where moves are instant
  instantSpeed: 'Instantâneo',

  // Human player
  human: 'Humano',
  // AI player risk level 1: Safe
  aiSafe: 'Seguro',
  // AI player risk level 2: Normal
  aiNormal: 'Normal',
  // AI player risk level 3: Risky
  aiRisky: 'Arriscado',
  // AI player risk level 4: Crazy
  aiCrazy: 'Louco',
  // AI player risk level 5: Extreme
  aiExtreme: 'Extremo',

  // AI player
  ai: 'IA',
  humanFull: '{human}',
  aiSafeFull: '{ai}: {aiSafe}',
  aiNormalFull: '{ai}: {aiNormal}',
  aiRiskyFull: '{ai}: {aiRisky}',
  aiCrazyFull: '{ai}: {aiCrazy}',
  aiExtremeFull: '{ai}: {aiExtreme}',

  // Note for when a feature is only available in the full edition:
  onlyInFull: 'Disponível apenas para usuários da {fullEdition}.',
  // The same as above but in brackets and shorter
  onlyInFullBrackets: '({fullEdition} apenas)',

  // MenuPage
  // {0} is the name of the app in bold
  welcomeToApp: 'Bem-vindo ao {0}!',
  // Welcome intro
  welcomeToAppDesc:
    'Use o menu de configurações para alterar seu nome de usuário e o menu de ajuda se não souber como jogar.',
  freeJoinPublic:
    "ATUALIZAÇÃO: Jogadores com a {basicEdition} agora podem entrar em jogos públicos! Toque em '{joinOnlineGame}' abaixo...",

  // Welcome message options
  // {0} is the username
  hiUser1: 'Oi, {0}!',
  hiUser2: 'Bem-vindo, {0}!',
  hiUser3: 'Olá, {0}!',
  hiUser4: 'Ei, {0}!',
  hiUser5: 'Olá, {0}!',

  // Button to create a new online game
  newOnlineGame: 'Criar Jogo Online',
  // Button to join an existing online game
  joinOnlineGame: 'Entrar em Jogo Online',
  // Button to start and play a new offline game
  newOfflineGame: 'Novo Jogo Offline',
  // Confirming with the user that they want to start a new offline game when they already have an existing game
  newOfflineGameConfirm: 'Novo Jogo Offline?',
  newOfflineGameConfirmDesc: 'Isso encerrará o jogo offline atual. {areYouSure}',
  // Button to continue playing an existing offline game
  continueOfflineGame: 'Continuar Jogo Offline',

  // Button to take the user to their active games
  // {0} is the number of active games the user has
  yourXGames: 'Seu {0} Jogo',
  yourXGames_plural: 'Seus {0} Jogos',

  // Button to take the user to one specific game
  // {0} is the game ID
  goToRoom: 'Ir para #{0}',

  // Button to join a specific game (usually by invitation)
  // {0} is the game ID
  joinX: 'Entrar em #{0}',

  // Button to go to the game rules
  howToPlay: 'Como jogar',

  // Rules heading
  rules: 'Regras',
  // Rules intro lines
  rulesIntro1:
    'A cada rodada, o jogador lança os quatro dados e os divide em dois pares, somando cada par. Por exemplo, um jogador que rola um 1, 2, 3 e 6 poderia agrupá-los como 5 e 7, 4 e 8 ou 3 e 9.',
  rulesIntro2:
    'Escolha um par para mover suas peças nas colunas correspondentes. Você tem um número limitado de peças por turno.',
  rulesIntro3:
    'Se não houver combinações de dados possíveis, sua jogada termina e as peças são perdidas. Se você optar por parar, suas peças serão substituídas pela sua cor.',
  rulesIntro4:
    'As colunas 2 e 12 são curtas, mas é raro rolar esses valores, enquanto a coluna 7 é a mais longa, mas a mais comum.',
  rulesIntro5:
    'Quando um jogador chega ao topo de uma coluna e para, ele reivindica a coluna, que é adicionada à sua pontuação. Essa coluna não pode mais ser jogada pelo restante do jogo. Quando um jogador atinge a pontuação alvo, o jogo termina.',

  // Online games with other people
  onlineGames: 'Jogos Online',
  onlineGamesDesc:
    'Você pode jogar online contra outros jogadores, criando seu próprio jogo público ou privado, ou participando de um jogo existente.',

  // Offline games with friends in the same room or against AI
  offlineGames: 'Jogos Offline ("Passar e Jogar")',
  offlineGamesDesc:
    'Você pode jogar um jogo offline com um dispositivo e passar o dispositivo entre os jogadores.',

  // Section about the original board game
  originalRules: 'Jogo Original',
  videoIntro:
    '"Can\'t Stop, Won\'t Stop" é inspirado no jogo de tabuleiro original, "Can\'t Stop", mas não é afiliado a ele de forma alguma. Aqui estão alguns vídeos do YouTube explicando o jogo de tabuleiro original, caso você nunca tenha jogado:',
  // {0} is the number of the video (e.g. 1 => "Video 1")
  videoX: 'Vídeo {0}',

  // Privacy Policy
  privacyPolicyDesc:
    'Nenhum dos seus dados pessoais é coletado. Suas configurações de aplicativo e os jogos que você joga são enviados para um servidor, mas os dados são mantidos apenas temporariamente e nunca são compartilhados com terceiros. Adicionar seu endereço de e-mail é opcional e é usado apenas para recuperar sua conta. Envie um e-mail para chris@mento.co.uk para solicitar que seus dados sejam excluídos.',

  probabilityCalculator: 'Calculadora de Probabilidade',
  probabilityCalculatorDesc:
    'Criei uma pequena ferramenta para ajudá-lo a entender as probabilidades de rolar para uma ou mais colunas. Pode ser útil!',

  // Game settings
  // {0} is the number of columns
  xColumns: '{0} Coluna',
  xColumns_plural: '{0} Colunas',
  xColumnsAltDesc: 'Número de colunas necessárias para vencer.',
  // {0} is the number of columns
  xColumnsDesc: 'O primeiro jogador a completar {0} coluna vencerá o jogo.',
  xColumnsDesc_plural: 'O primeiro jogador a completar {0} colunas vencerá o jogo.',

  // {0} is the number of markers
  xMarkers: '{0} Peça',
  xMarkers_plural: '{0} Peças',
  xMarkersAltDesc: 'Número máximo de colunas por jogada.',
  // {0} is the number of columns
  xMarkersDesc: 'Você pode ocupar no máximo {0} coluna por vez.',
  xMarkersDesc_plural: 'Você pode ocupar no máximo {0} colunas por vez.',

  // Optional game mode 1
  forceMax: 'Forçar o máximo de marcadores',
  forceMaxDesc:
    'O jogador deve escolher uma combinação de dados que utilize o maior número de novos marcadores.',

  // Optional game mode 2
  preventSkip: 'Impedir turnos pulados',
  preventSkipDesc: 'Role novamente para que um jogador nunca perca a vez inteira.',

  // Optional game mode 3
  preDetermine: 'Pré-determinar a próxima rolagem de dados',
  preDetermineDesc:
    'Os próximos números dos dados são rolados aleatoriamente antes de você tocar em {continue} ou {stop}.',

  // Optional game mode 4
  doubleHeight: 'Altura Dupla',
  doubleHeightDesc: 'Todas as colunas têm o dobro da altura, então os jogos duram mais.',

  // Public games can be seen and joined by anyone
  public: 'Público',
  public_full: 'Jogo Público',
  publicDesc: 'Jogos públicos podem ser vistos e juntados por qualquer pessoa.',

  // Private games can only be joined using the room code
  private: 'Privado',
  private_full: 'Jogo Privado',
  privateDesc: 'Jogos privados só podem ser acessados usando o código da sala.',

  // Heading for the player types (which version of the app each player is using)
  userTypes: 'Tipos de Jogadores',

  // Game Info
  // {0} is the game ID
  currentGameX: 'Jogo Atual #{0}',
  // {0} is a relative time in the past, for example "5 minutes ago"
  lastActivityX: 'Última atividade {0}',

  // For when a time is too recent to show the exact time
  justNow: 'agora mesmo',

  // Alert message shown when the user presses the button to copy something to the clipboard
  gameIdCopied: '{gameId} copiado para a área de transferência!',
  websiteCopied: 'Website copiado para a área de transferência!',

  // Waiting
  // {0} is the number of players joined so far
  // {1} is the total number of players that are needed for the game to start
  xOfYPlayersJoined: '{0} de {1} jogadores se juntaram:',

  // Winning turn
  // {0} is the column number(s)
  // {1} is the Turn Progress percentage
  winningMoveSummaryShort: '{0} · {1}%',
  winningMoveSummaryLong: "Finalizado completando a coluna {0} com um 'Progresso da Vez' de {1}%",

  // Full turn progress for the tooltip
  // {0} is the Turn Progress percentage
  turnProgressFull: "O 'Progresso da Vez' atual é {0}%",
  // If it was the previous player's turn:
  turnProgressLast: "O 'Progresso da Vez' anterior foi {0}%",

  // Cancel button
  cancel: 'Cancelar',

  // Button to fully cancel/end the game
  cancelGame: 'Cancelar Jogo',
  // Confirmation about cancelling the game before it starts
  cancelGameDesc: 'Isto cancelará o jogo para todos. Você tem certeza?',

  // Button to decline a rematch
  declineRematch: 'Recusar Rematch',
  // Button to view the previous game from a rematch
  viewPrevious: 'Ver Jogo Anterior',

  // Share App
  shareMessage: "🎲🎲 Junte-se a mim e jogue Can't Stop, o viciante jogo de dados! 🎲🎲",

  // QR linked devices
  linkToWeb: 'Conectar ao Web',
  linkToWebDesc: 'Ver e jogar seus jogos na {webEdition}.',

  // Button to leave the game when waiting for other players to join
  leaveGame: 'Sair do Jogo',

  // Button to join a game
  joinGame: 'Juntar-se ao Jogo',
  // Button to join a rematch game
  joinRematch: 'Juntar-se ao Rematch',

  // {0} is the website URL
  inviteAtX: 'Convide amigos para se juntar gratuitamente em {0}',

  gameId: 'ID do Jogo',
  gameIdHash: '{gameId} #',

  // Join button
  join: 'Juntar-se',
  enterGameId: 'Digite o {gameId} acima para se juntar ao jogo privado de um amigo.',
  // {0} is the name of the app
  listGamesFullApp: 'Se você quiser poder ver uma lista de jogos públicos, precisará do app {0}:',
  noPublicGames: 'Atualmente não há jogos públicos para se juntar.',
  newGameFullApp:
    'Por que não criar um novo jogo e convidar seus amigos para se juntarem gratuitamente (com o app {basicEdition} ou {webEdition})?',
  publicGames: 'Jogos Públicos',
  waiting: 'Aguardando',
  publicGamesWaiting: '{publicGames}: {waiting}',
  inProgress: 'Em Progresso',
  publicGamesInProgress: '{publicGames}: {inProgress}',
  recentlyFinished: 'Recentemente Finalizados',
  publicGamesRecentlyFinished: '{publicGames}: {recentlyFinished}',

  // New Game
  createOnlineGame: 'Criar Jogo Online',
  createOnlineGameDesc:
    'Crie um jogo online e então convide seus amigos ou aguarde que outros jogadores se juntem!',
  createOfflineGame: 'Criar Jogo Offline',
  createOfflineGameDesc:
    "Crie um jogo 'Pass and Play' offline para jogar com amigos na mesma sala ou apenas para praticar.",
  // {0} is the name of the app
  noNewGameWeb:
    'A Edição Web de {0} só permite que você se junte a jogos pelo ID. Se você quiser criar novos jogos, precisará do app:',
  // {0} is the name of the app
  noNewGameBasic:
    'A Edição Básica de {0} só permite que você se junte a jogos e crie jogos offline. Se você quiser criar um novo jogo online, precisará do app completo sem anúncios:',
  basicLimitMessage:
    'A Edição Básica de {0} só permite que você crie cinco jogos ao mesmo tempo com configurações limitadas. Se você quiser mudar as opções ou criar mais jogos, precisará do app completo sem anúncios:',
  // {0} is the number of players
  xPlayers: '{0} Jogadores',
  xPlayersDesc: 'Número de jogadores no jogo.',

  // Conceding
  concede: 'Conceder',
  conceded: 'Concedido',
  xConceded: '{0} concedeu.',
  youConceded: 'Você concedeu.',
  xGamesConceded: '{0} jogo concedido ({1}%)',
  xGamesConceded_plural: '{0} jogos concedidos ({1}%)',

  // Games played
  xGamesPlayed: '{0} jogo completo',
  xGamesPlayed_plural: '{0} jogos completos',
  xGamesPlaying: '{0} jogo ativo',
  xGamesPlaying_plural: '{0} jogos ativos',
  // xStopRate: 'Recent: {1}% / Overall: {0}%',
  xStopRate: 'Taxa de Parada de {0}',
  xStopRateDesc: '(turnos terminando com uma parada)',

  vsFormDesc: 'Seus resultados recentes contra {0}',

  recent: 'Recente',
  allTime: 'De Todos os Tempos',

  concedeCheck:
    'Você tem certeza de que deseja conceder este jogo? Não pode ser desfeito e pode afetar sua pontuação/status.',

  // Rematch
  rematch: 'Rematch',
  invited: 'Convidado',
  rematchProposed: 'Rematch Proposto',

  // Replay from here button
  replay: 'Repetir a Partir Daqui',
  // Button to stop the replay
  stopReplay: 'Parar Replay',

  // Recent form: Win
  formWin: 'Vitória',
  // Recent form: Loss
  formLoss: 'Derrota',
  // Recent form: Conceded
  formConceded: 'Concedido',

  recentForm: 'Forma Recente de {0}',

  // Game Modes

  // The classic normal rules of the game
  classic: 'Clássico',
  classicFull: 'Modo Clássico',
  classicDesc: 'As regras clássicas.',
  // The "blocking" variant of the game
  block: 'Bloqueio',
  blockFull: '"Variante de Bloqueio"',
  blockDesc: 'Parar em qualquer uma das mesmas posições que outro jogador é bloqueado.',

  // The "Jumping" variant of the game
  jump: 'Pular',
  jumpFull: '"Variante de Pular"',
  jumpDesc:
    'Pular automaticamente sobre outros jogadores para que os jogadores nunca estejam nas mesmas posições.',

  // Game
  youWin: 'Você Ganhou!',
  youWon: 'Você Venceu!',
  // {0} is the winning player name
  xWins: '{0} Vence!',
  xWon: '{0} Venceu',
  youLost: 'Você Perdeu',
  xWinRate: '{0}% taxa de vitória',
  neither: 'Nenhum',

  // Alert when there are no more possible moves
  noPossibleMoves: 'Sem movimentos possíveis',

  // Button to stop and pass to the next player
  stop: 'Parar',

  gameOver: 'Fim de Jogo!',
  // Button to start a new game
  newGame: 'Novo Jogo',
  // Notification and history
  gameStarted: 'Jogo iniciado!',
  // Button to end the game early
  endGame: 'Encerrar Jogo',
  startGame: 'Iniciar Jogo',
  nextGame: 'Próximo Jogo',

  // Confirmation about ending the game early
  endGameEarly: 'Encerrar Jogo Antecipadamente?',
  endGameEarlyDesc: 'Isso encerrará o jogo atual para todos. Você tem certeza?',

  yourTurn: 'Sua Vez',
  // {0} is the player's name
  xTurn: "{0}'s Turn",
  // The status of a finished game
  finished: 'Finalizado',
  // The status of a game in progress
  playing: 'Jogando',
  // {0} is the number of players
  waitingForX: 'Aguardando {0}',
  // {0} is the number of players
  waitingForXPlayers: 'Aguardando {0} jogador',
  waitingForXPlayers_plural: 'Aguardando {0} jogadores',
  // {0} is a relative time in the past, for example "2 days ago"
  createdX: 'Criado {0}',

  // New version
  newVersion: 'Nova Versão Disponível',
  newVersionDesc: 'A versão {0} está pronta para download agora.',

  // Beta Tester
  becomeTester: 'Tornar-se um testador?',
  becomeTesterDesc:
    'Se você gostaria de experimentar novos recursos antes, por favor, me envie um e-mail sobre a adesão ao grupo de testadores voluntários!',
  // Button to decline joining as a tester
  noThanks: 'Não, Obrigado!',

  // Warning message when push notification permissions are denied
  pushDeniedTitle: 'As notificações push estão atualmente desativadas para este aplicativo.',
  pushDeniedDesc:
    'Sem elas, você não será alertado quando for sua vez e pode correr o risco de conceder jogos automaticamente.',

  // Settings
  settings: 'Configurações',
  // Button to set/save the player's username
  set: 'Definir',
  // To change if the app will make sounds or not
  sounds: 'Sons',
  // The player's name visible to others online
  username: 'Nome de Usuário',
  // The app's language
  language: 'Idioma',
  // Lock screen awake
  lockScreen: 'Manter a tela acordada durante os jogos',
  // Player colour settings
  playerColours: 'Cores dos Jogadores',
  // emoji tone
  emojiTone: 'Tom do Emoji',

  // Button to show more of the history moves
  showMore: 'Mostrar Mais',

  // User Key (for switching accounts)
  userKey: 'Chave da Conta do Usuário',
  userKeyDesc: `Aviso: use isso apenas se souber o que está fazendo. Não compartilhe com ninguém.`,

  // Send email requesting deletion of user account
  requestDeleteAccount: 'Solicitar Exclusão da Conta',

  // Show last turn in the display
  lastTurnSetting: `Visualizar a última jogada de cada jogador`,

  // Turn Progress toggle
  turnProgressSetting: `Mostrar o 'Progresso da Jogada' durante os jogos`,
  turnProgressSettingDesc: `'Progresso da Jogada' é a porcentagem das colunas atuais completadas. Por exemplo, se você conseguir completar todas as 3 colunas do zero em uma jogada, ele mostraria 100%. Uma vez ativado, será exibido no canto superior direito da tela do jogo, no painel de Histórico e na tela de rematch.`,

  // *** Keyboard Shortcuts ***

  // Turn on/off keyboard shortcuts for games
  keyShortcutsSetting: 'Atalhos de Teclado',

  // Spacebar key
  keySpace: 'Espaço',

  // Ctrl key
  keyCtrl: 'Ctrl',

  // Shift key
  keyShift: 'Shift',
  // "Next" action
  keyActionNextDesc: '"Próximo" (continuar, próximo cômodo ou opção de dado único).',
  keyActionNextNote: 'Nota: Quando há um botão "próximo", ele terá uma borda.',

  // Dice option
  keyActionNumDesc: 'Escolha a opção de dado com esse número.',
  keyActionNumNote: 'Nota: Se apenas uma opção existir para 10-12, você só precisa pressionar 1.',

  // Dice position
  keyActionCtrlNumDesc: 'Escolha a opção de dado nessa posição.',
  // {0} is Ctrl (or another key)
  keyActionCtrlNumNote: 'por exemplo, {0}+1 é a primeira opção',

  // Action to open the keyboard shortcuts instructions
  keyActionOpenHelpDesc: 'Abra estas instruções.',

  includeLostGames: 'Incluir jogos perdidos em Próximos Jogos',
  includeLostGamesDesc:
    'Os botões "Ir para #" (que levam você à sua próxima jogada) incluirão jogos que você perdeu recentemente.',

  you: 'Você',

  // Your Games
  yourGames: 'Seus Jogos',
  notInGames: 'Você não está atualmente em nenhum jogo ativo.',

  // Errors
  waitingLimit: 'Você atingiu seu limite de novos jogos que ainda estão aguardando para começar.',
  basicLimit: 'A {basicEdition} permite apenas que você crie cinco jogos ao mesmo tempo.',
  serverTimeout: 'Tempo limite do servidor. Tente novamente.',
  samePlayersLimit:
    'Você atingiu o limite para o número de jogos ativos com os mesmos jogadores exatos.',

  // Bot check
  typeOk: 'Por favor, digite "ok" abaixo para continuar...',

  // Error page
  somethingWrong: 'Algo deu errado!',
  somethingWrongDesc:
    'Desculpe! Por favor, você pode me enviar um e-mail com as seguintes informações e uma breve descrição do que você estava fazendo e eu consertarei isso o mais rápido possível:',

  // Common app localisations:
  privacyPolicy: 'Política de Privacidade',
  // Link to support the developer
  supportDeveloper: 'Compre um café para o desenvolvedor',

  // Button to say no
  no: 'Não',
  // Button to say yes
  yes: 'Sim',
  // Close/exit button
  close: 'Fechar',
  // Button to continue playing
  continue: 'Continuar',
  comingSoon: 'Em breve!',
  reset: 'Reiniciar',
  ok: 'OK',

  // The app's colour theme (light/dark)
  appTheme: 'Tema do App',
  appThemeSystem: 'Padrão do Sistema',
  appThemeLight: 'Modo Claro',
  appThemeDark: 'Modo Escuro',

  // *** User Account ***
  signedInAsX: 'Conectado como {0}',
  signedInDesc: 'Você pode se conectar a partir de outros dispositivos.',
  signOut: 'Sair',
  notSignedIn: 'Não conectado.',
  signInToSave:
    'Conecte-se para salvar sua conta de jogador e configurações. Você pode se conectar a partir de outros dispositivos!',
  signInNew:
    'Novo no aplicativo? Cadastre-se para salvar sua conta de jogador e configurações. Você pode se conectar a partir de outros dispositivos! Se você já tem uma conta, faça login abaixo.',
  signInRegister: 'Entrar / Registrar',

  // The 3 below are joined together to form one message with the middle as a button
  guestModeWarning1: 'Você está usando o modo Convidado.',
  guestModeWarning2Btn: 'Entrar',
  guestModeWarning3: 'para proteger sua conta e jogar de outros dispositivos.',

  // Screenshot text
  // Each screenshot has text above and text below, which is why each of the below are in 2:
  screenshotPlayAgainst: `"Não Consiga Parar" Jogo de Dados`,
  screenshotPlayAgainst2: 'Jogue Contra Amigos, Online e Offline',
  screenshotCreateOnline: 'Crie Jogos Online',
  screenshotCreateOnline2: 'Incluindo variantes "Pulando" e "Bloqueio"!',
  screenshotPlayFriends: 'Jogue Com Amigos',
  screenshotPlayFriends2: 'Até 4 Jogadores Online!',
  screenshotJoinPublicPrivate: 'Participe de Jogos Públicos ou Privados',
  screenshotJoinPublicPrivate2: 'Jogue contra estranhos também!',
  screenshotOffline: "Passar 'n' Jogar",
  screenshotOffline2: 'Jogue Jogos Offline Com Amigos',
  screenshotInvite: 'Convide Amigos para Jogar Gratuitamente',
  screenshotInvite2: 'Usando o aplicativo Edição Básica ou Edição Web',
  screenshotLimitedFree: 'Recursos Limitados com Edição Básica',
  screenshotLimitedFree2: 'Com suporte a anúncios e não pode criar jogos online.',
  screenshotSettings: 'Mude as Configurações',
  screenshotSettings2: 'Incluindo modos claro e escuro',

  // App listing
  appStoreNameFull: 'Não Consiga Parar: Jogo de Dados', // Maximum 50 characters
  appStoreSubtitleFull: 'Jogue com seus amigos e role os dados!', // Maximum 80 characters
  appStoreDescriptionFull: `Jogo de dados popular e totalmente funcional baseado no jogo de tabuleiro "Não Consiga Parar". Jogue online ou offline (Passar e Jogar). 2-4 jogadores.

    Experimente diferentes variantes ou fique com a versão clássica!
    
    Seus amigos podem entrar no seu jogo mesmo que não tenham o aplicativo. O aplicativo é necessário para criar novos jogos e jogar jogos offline.`,

  appStoreNameBasic: 'Não Consiga Parar: Jogo de Dados (Básico)', // Maximum 50 characters
  appStoreSubtitleBasic: 'Jogue com seus amigos e role os dados gratuitamente!', // Maximum 80 characters
  appStoreDescriptionBasic: `Esta é a Edição Básica do popular jogo de dados baseado no jogo de tabuleiro "Não Consiga Parar". Jogue online ou offline (Passar e Jogar). 2-4 jogadores.

    Experimente diferentes variantes ou fique com a versão clássica!
    
    O aplicativo completo é necessário para criar novos jogos online.`,
};

export default pt;
